import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SpotsService } from './../../services/spots.service';

@Component({
  selector: 'app-filtri',
  templateUrl: './filtri.component.html',
  styleUrls: ['./filtri.component.css']
})
export class FiltriComponent implements OnInit {

  constructor(
    public activeModal: NgbActiveModal,
    public spotsService: SpotsService,
  ) { }

  ngOnInit(): void {
  }

  close() {
    this.activeModal.close();
  }

  filterSelect(seleccion) {
    this.spotsService.filterSelected = seleccion;
    this.close();

   
  }

}
