import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { GeneralService } from '../../services/general.service';
import { SpotsService } from './../../services/spots.service';
import { Ricupero } from './../../components/models/user-data.model';

@Component({
  selector: 'app-password-ricupera',
  templateUrl: './password-ricupera.component.html',
  styleUrls: ['./password-ricupera.component.css']
})
export class PasswordRicuperaComponent implements OnInit {

  public errorForm: boolean = false;
  public resultRes: boolean;
  public messageRes: string = '';
  public model = new Ricupero('');
  public codiceInviato = false;
  public msgSuccess = '';

  constructor(
    public generalService: GeneralService,
    public spotsService: SpotsService,
    private router: Router,
  ) {

    this.generalService.positionPage = 'ricupera';
  }

  ngOnInit() {
    let body = document.getElementsByTagName("body")[0];
    body.classList.add("bg-walki");
  }

  ngOnDestroy() {
    let body = document.getElementsByTagName("body")[0];
    body.classList.remove("bg-walki");
  }

  enviaRichiesta() {

    this.errorForm = false;
    this.messageRes = '';

    if (!this.generalService.isConnect) { return; }

    this.hideAlertError();

    let username = this.model.username.replace(/(^\s*)|(\s*$)/g, "");

    if (username == '') {

      this.showAlertError(this.generalService.words_traslate.ESCRIBEUSERNAME);

    } else {

      //hide overload
      this.generalService.loading = true;

      this.spotsService.richiestaNewPass(this.model).subscribe(data => {
      
     //   console.log(data)
        if (data['success']) {

          this.generalService.loading = false;
          this.codiceInviato = true;
          this.msgSuccess = data['message'];


        } else {

          this.generalService.loading = false;
          //  this.showAlertError(data['message']);
          this.messageRes = data['message'];
          this.errorForm = true;
        }

      }, error => {

        this.generalService.loading = false;
        this.generalService.crashServer(error)
      });

    }

  }

  // Show Alert And set Message
  showAlertError(message) {

    this.messageRes = message;
    this.errorForm = true;
  }

  // Hide Alert And empty Message
  hideAlertError() {

    this.errorForm = false;
    this.messageRes = '';
  }

}
