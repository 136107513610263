
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { NgbModal, NgbActiveModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { SpotsService } from './../../services/spots.service';
import { GeneralService } from './../../services/general.service';
import { ModImageManipulationService } from './../mod-image-manipulation/mod-image-manipulation.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';  // Importamos el objeto Subscription de rxjs
import { ChiudeSpotComponent } from './../chiude-spot/chiude-spot.component';

@Component({
  selector: 'app-mod-spot-manipulation',
  templateUrl: './mod-spot-manipulation.component.html',
  styleUrls: ['./mod-spot-manipulation.component.css']
})
export class ModSpotManipulationComponent implements OnInit, OnDestroy {

  @Input() type: string = 'new';
  @Input() spot;
  @Input() spotId;



  public imgURL;
  public data = { sender: null, timestamp: null, lat: null, lng: null };
  public mapG;
  public markers: google.maps.Marker[] = [];
  public existSpot = false;
  public statusImage: Subscription;
  public risoluzione: string = '';
  public fileName: string = '';

  public infowindow = new google.maps.InfoWindow({
    content: '',
  });

  constructor(
    public activeModal: NgbActiveModal,
    public router: Router,
    public spotsService: SpotsService,
    public modImageManipulationService: ModImageManipulationService,
    public generalService: GeneralService,
    private modalService: NgbModal,
  ) { }

  ngOnDestroy() {

  }
  ngOnInit() {

    this.existSpot = this.type == 'edit' ? true : false;

    if (!this.existSpot) {

      this.spot = {
        'spotImage': '',
        'titolo': '',
        'testo': '',
        'lat': '',
        'long': '',
        'tag': '',
      }

    }

    let mapFrame = document.getElementById('map-canvas-new');

    var mapGOptions = {
      zoom: 12,
      scrollwheel: false,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
    }

    // if position save 
    this.mapG = new google.maps.Map(mapFrame, mapGOptions);

    const styles: Record<string, google.maps.MapTypeStyle[]> = {
      default: [],
      hide: [
        { featureType: "poi.business", stylers: [{ visibility: "off" }], },
        { featureType: "transit", elementType: "labels.icon", stylers: [{ visibility: "off" }], },
      ],
    };

    this.mapG.setOptions({ styles: styles["hide"] });

    //== Center Map and push marker if type == edit
    let pos = { lat:this.generalService.myLat, lng: this.generalService.myLng };

    if (this.type == 'edit') {

      pos = {
        lat: Number(this.spot.lat),
        lng: Number(this.spot.long),
      };

      this.generalService.myLat = Number(this.spot.lat);
      this.generalService.myLng = Number(this.spot.long);

      localStorage.setItem('spotyLat', this.spot.lat.toString());
      localStorage.setItem('spotyLng', this.spot.long.toString());

    } else {

      if (localStorage.getItem('spotyLat') && localStorage.getItem('spotyLng')) {

        this.generalService.myLat = Number(localStorage.getItem('spotyLat'));
        this.generalService.myLng = Number(localStorage.getItem('spotyLng'));

        pos = {
          lat: Number(localStorage.getItem('spotyLat')),
          lng: Number(localStorage.getItem('spotyLng')),
        };

        if (localStorage.getItem('zoomMap')) {
          this.mapG.setZoom(Number(localStorage.getItem('zoomMap')));
        }

      } else {

        /*this.generalService.myLat = 44.414165;
        this.generalService.myLng = 8.942184;*/

        localStorage.setItem('spotyLat', this.generalService.myLat.toString());
        localStorage.setItem('spotyLng', this.generalService.myLng.toString());

        pos = {
          lat: this.generalService.myLat,
          lng: this.generalService.myLng,
        }

      }

    }

    this.mapG.setCenter(pos);

    if (this.type == 'edit') {

      let latLng = new google.maps.LatLng(Number(this.spot.lat), Number(this.spot.long));

      const marker = new google.maps.Marker({
        position: latLng,
        map: this.mapG,
      });

      this.markers.push(marker);
    }


    // Listen for clicks and add the location of the click to firebase.
    this.mapG.addListener('click', (e) => {

      for (let i = 0; i < this.markers.length; i++) {
        this.markers[i].setMap(null);
        this.markers.pop();
      }

      this.spot.lat = e.latLng.lat();
      this.spot.long = e.latLng.lng();

      this.generalService.myLat = Number(e.latLng.lat());
      this.generalService.myLng = Number(e.latLng.lng());

      localStorage.setItem('spotyLat', e.latLng.lat().toString());
      localStorage.setItem('spotyLng', e.latLng.lng().toString());

      const marker = new google.maps.Marker({
        position: { lat: e.latLng.lat(), lng: e.latLng.lng() },
        map: this.mapG,
      });

      this.markers.push(marker);

    });

    this.mapG.addListener("dragend", () => {


      let mylat = this.mapG.getCenter().lat();
      let mylng = this.mapG.getCenter().lng();

      this.generalService.myLat = Number(mylat);
      this.generalService.myLng = Number(mylng);

      localStorage.setItem('spotyLat', mylat.toString());
      localStorage.setItem('spotyLng', mylng.toString());

    });

  }

  private viewStatusImage(): void {

    this.statusImage = this.spotsService.getStatusImage().subscribe(
      status => {


        if (status.isResult) {

          if (!status.success) {

            this.generalService.runToast(status.success, status.message);


          } else {

            this.type == 'edit' ? this.goConfirmaEdit(status.url, true, status.filename) : this.goConfirmaNew(status.url, status.filename);
            this.fileName = status.filename;
          }

          // Cancelamos la suscripción cuando se destruya el componente
          this.statusImage.unsubscribe();
          this.spotsService.setStatusImage({ isResult: false, success: false, message: '', filename: '' })

        }



      });
  }

  close() {
    this.activeModal.close();
  }

  setMyPos() {

    if (!this.generalService.navigatorIsOk) {
      alert(this.generalService.words_traslate.INFOGEONOSOPORTADA);
      return;
    }

    if (!this.generalService.acceptGeo) {
      alert(this.generalService.words_traslate.ACTIVAGEOLO);
      return;
    }

    let infoWindow = new google.maps.InfoWindow({
      content: ''
    });

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {

          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };

          for (let i = 0; i < this.markers.length; i++) {
            this.markers[i].setMap(null);
            this.markers.pop();
          }

          this.spot.lat = position.coords.latitude.toString();
          this.spot.long = position.coords.longitude.toString();

          this.mapG.setCenter(pos);

          this.generalService.myLat = Number(position.coords.latitude);
          this.generalService.myLng = Number(position.coords.longitude);

          localStorage.setItem('spotyLat', position.coords.latitude.toString());
          localStorage.setItem('spotyLng', position.coords.longitude.toString());
          const marker = new google.maps.Marker({
            position: { lat: position.coords.latitude, lng: position.coords.longitude },
            map: this.mapG,
          });

          this.markers.push(marker);

        },
        () => {
          this.handleLocationError(true, infoWindow, this.mapG.getCenter());
        }
      );
    } else {
      this.handleLocationError(false, infoWindow, this.mapG.getCenter());
    }
  }

  handleLocationError(browserHasGeolocation: boolean, infoWindow: google.maps.InfoWindow, pos: google.maps.LatLng) {

    infoWindow.setPosition(pos);
    infoWindow.setContent(
      browserHasGeolocation
        ? "Error: The Geolocation service failed."
        : "Error: Your browser doesn't support geolocation."
    );
  }

  confirma() {

    let formSpot: boolean = true;
    let msgs: Array<string> = [];
    let newImageInSpot = false;

    if (this.existSpot) {

      if (this.modImageManipulationService.srcOldPreview == '') {

        if (this.modImageManipulationService.resultImage) {
          newImageInSpot = true;
        } else {

          msgs.push(this.generalService.words_traslate.INCLUYEIMAGENENLAPUBLICACION);
          formSpot = false;

        }

      }

    } else {
      if (this.modImageManipulationService.resultImage) {
        newImageInSpot = true;
      } else {
        msgs.push(this.generalService.words_traslate.INCLUYEIMAGENENLAPUBLICACION);

        formSpot = false;
      }
    }

    if (this.spot.titolo) { } else {
      msgs.push(this.generalService.words_traslate.ESCRIBETITULO);
      formSpot = false;
    }

    if (this.spot.testo) { } else {
      msgs.push(this.generalService.words_traslate.ESCRIBETEXTO);
      formSpot = false;
    }

    if (this.spot.tag == 'selected') {
      msgs.push(this.generalService.words_traslate.SELEZIONACATEGORIA);
      formSpot = false;
    } else {

    }

    if (this.spot.lat) {

    } else {
      msgs.push(this.generalService.words_traslate.LOCALIZAPOST);
      formSpot = false;
    }

    if (formSpot) {


      if (this.existSpot) {

        if (newImageInSpot) {

          this.viewStatusImage();

          this.generalService.loading = true;
          //  this.goConfirmaNew('https://spotsimagebucket.s3.eu-central-1.amazonaws.com/immagine.png')
          this.spotsService.uploadImage(this.modImageManipulationService.resultImage, this.modImageManipulationService.resultImage.name, this.modImageManipulationService.resultImage.type);

        } else {
          this.goConfirmaEdit(this.modImageManipulationService.srcOldPreview, false, '')
        }


      } else {


        this.viewStatusImage();

        this.generalService.loading = true;
        //  this.goConfirmaNew('https://spotsimagebucket.s3.eu-central-1.amazonaws.com/immagine.png')
        this.spotsService.uploadImage(this.modImageManipulationService.resultImage, this.modImageManipulationService.resultImage.name, this.modImageManipulationService.resultImage.type);


      }





    } else {

      this.generalService.errorListToast(this.generalService.words_traslate.NECESITAMOSMASINFO, msgs);

    }

  }

  goConfirmaNew(url, filen) {

    let nuevoSpot

    nuevoSpot = {
      'spotImage': url,
      'newFileName': filen,
      'titolo': this.spot.titolo,
      'testo': this.spot.testo,
      'lat': this.spot.lat,
      'long': this.spot.long,
      'tag': this.spot.tag,
    }

    //console.log(nuevoSpot);
    this.spotsService.inviaNuovoSpot(nuevoSpot).subscribe(data => {

      if (data['success']) {

        this.spotsService.addingSpotInLists(data['spot']);
        this.activeModal.close({ success: data['success'], message: data['message'], spot: data['spot'] });
        // 

      }

      this.generalService.loading = false;
      this.generalService.runToast(data['success'], data['message']);


    }, error => {

      this.generalService.loading = false;
      this.generalService.crashServer(error);
    });

  }

  goConfirmaEdit(url, isSubscript, fileName) {

    this.generalService.loading = true;


    let nuevoSpot
    if (fileName.replace(/(^\s*)|(\s*$)/g, "") == '') {

      nuevoSpot = {
        'nuovaSpotImage': url,
        'nuovoTitolo': this.spot.titolo,
        'nuovoTesto': this.spot.testo,
        'nuovaLat': this.spot.lat,
        'nuovaLong': this.spot.long,
        'nuovoTag': this.spot.tag.toLowerCase(),
      }
    } else {

      nuevoSpot = {
        'nuovaSpotImage': url,
        'newFileName': fileName,
        'nuovoTitolo': this.spot.titolo,
        'nuovoTesto': this.spot.testo,
        'nuovaLat': this.spot.lat,
        'nuovaLong': this.spot.long,
        'nuovoTag': this.spot.tag.toLowerCase(),
      }
    }

    this.spotsService.inviaeditSpot(this.spot._id, nuevoSpot).subscribe(data => {

      if (data['success']) {

        this.generalService.loading = false;
        this.generalService.runToast(data['success'], data['message']);
        // this.spotsService.updateSpot(this.spot._id)

        let spotAggiornadoS = data['spot'];
        let spotAggiornadoC = this.spotsService.createSingleSpot(this.spotsService.username, data['spot']);
        this.spotsService.refrehDetailsPostInLists(this.spot._id, spotAggiornadoS, spotAggiornadoC);
        this.spotsService.refreshDetails(this.spot._id, spotAggiornadoC);

        if (isSubscript) {
          this.statusImage.unsubscribe();
          //  this.spotsService.setUserDetails();
        }
        this.activeModal.close({ success: data['success'], message: data['message'] });

      } else {

        this.generalService.loading = false;
        this.generalService.runToast(data['success'], data['message']);

      }

    }, error => {

      this.generalService.loading = false;
      (isSubscript) ? this.statusImage.unsubscribe() : false;
      this.generalService.crashServer(error);

    });
  }

}
