import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-grazie',
  templateUrl: './grazie.component.html',
  styleUrls: ['./grazie.component.css']
})
export class GrazieComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
