import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-info-connection',
  templateUrl: './info-connection.component.html',
  styleUrls: ['./info-connection.component.css']
})
export class InfoConnectionComponent implements OnInit {

  constructor() { }
  ngOnInit(): void { }

}
