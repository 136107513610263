import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ModImageManipulationComponent } from './mod-image-manipulation.component';
import { NgxImageCompressService } from 'ngx-image-compress';
import { SharedTranslateModule } from './../shared-translate/shared-translate.module';

@NgModule({
  declarations: [
    ModImageManipulationComponent
  ],
  imports: [
    CommonModule,
    ImageCropperModule,
    SharedTranslateModule
  ],
  providers: [
    NgxImageCompressService
  ],
  exports: [
    ModImageManipulationComponent
  ]
})
export class ModImageManipulationModule { }
