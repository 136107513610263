import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { SpotsService } from './../../services/spots.service';
import { GeneralService } from './../../services/general.service'
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';  // Importamos el objeto Subscription de rxjs

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.css']
})
export class MessagesComponent implements OnInit {

  @Input() idSpot: string;
  @Input() comments: Array<any>;
  @Input() editAllowed: boolean;
  @Input() numComments: number;
  @Input() isInList: boolean;
  @Input() spot;

  comment = '';

  isCollapsed = true;

  constructor(
    public spotsService: SpotsService,
    public generalService: GeneralService,
    private modalService: NgbModal,

  ) { }

  ngOnInit(): void { }

  sendComm() {
    this.spotsService.sendComment(this.idSpot, this.comment);
    this.comment = '';
    // console.log(this.spot)
  }

  deleteComment(id) {

    if (confirm(this.generalService.words_traslate.QUIERESELIMINARMENSAJE)) {

      this.generalService.loading = true;
      this.spotsService.deleteComment(this.idSpot, id);

    }
  }


  openSendComment() {

    let modal = this.modalService.open(NgbdModalContent, { size: 'xl', backdrop: 'static' });
    (<NgbdModalContent>modal.componentInstance).idSpot = this.idSpot;
  }


}

@Component({
  selector: 'ngbd-modal-content',
  template: `
    <div class="modal-header">
      <!--<h4 class="modal-title">Scrivi un commento</h4>-->
      <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
    <div class="pl-lg-4">
     <div class="form-group">
      <div class="row" style='margin-bottom: 10px;'>
        <div class="col"></div>
          <div class="col-auto">
            <button type="button" class="btn bg-ws btn-sm" (click)='sendCommt(comentario.value)'> <i class="ni ni-send"></i>  {{ generalService.words_traslate.ENVIARCOMENTARIO }} </button>
          </div>
        </div>
        <textarea rows="4" #comentario id="comentario" class="form-control form-control-alternative" placeholder=" {{ generalService.words_traslate.ESCRIBEUNCOMENTARIO }} "></textarea>

        <div class="row" style='margin-top: 10px;'>
            <div class="col"></div>
            <div class="col-auto">
                <button type="button" class="btn bg-ws btn-sm" (click)='sendCommt(comentario.value)'> <i class="ni ni-send"></i> {{ generalService.words_traslate.ENVIARCOMENTARIO }} </button>
            </div>
        </div>

    </div>
</div>
    </div>
  `
})
export class NgbdModalContent implements OnInit, OnDestroy {

  @Input() idSpot;


  certificateUser = this.spotsService.dataUser.certificateUser;
  public status: Subscription;

  constructor(
    public activeModal: NgbActiveModal,
    public spotsService: SpotsService,
    public generalService: GeneralService,
  ) { }

  ngOnInit(): void {
    this.generalService.updateStatusProcees(true)
    this.listenStatus();
  }

  private listenStatus(): void {

    this.status = this.generalService.getStatusProcees().subscribe(
      detalle => {

        if (!detalle) {
          this.activeModal.close();
        }
        //   this.spot = detalle

      });
  }

  ngOnDestroy(): void {
    this.status.unsubscribe(); 	// Cancelamos la suscripción cuando se destruya el componente
  }

  sendCommt(comentario) {
    this.spotsService.sendComment(this.idSpot, comentario);
  }

}
