import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-verificata-email',
  templateUrl: './verificata-email.component.html',
  styleUrls: ['./verificata-email.component.css']
})
export class VerificataEmailComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
