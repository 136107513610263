import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { OverloadComponent } from './overload/overload.component';
import { NavbarComponent } from './navbar/navbar.component';
import { RouterModule } from '@angular/router';
import { SpotcardComponent } from './spotcard/spotcard.component';
import { FooterComponent } from './footer/footer.component';
import { ValidatorPasswordComponent } from './validator-password/validator-password.component';
import { SpotDetailsComponent } from './spot-details/spot-details.component';
import { ModalYolliComponent } from './modal-yolli/modal-yolli.component';
import { InfoConnectionComponent } from './info-connection/info-connection.component';
import { MessagesComponent } from './messages/messages.component';
import { FollowOrNotComponent } from './follow-or-not/follow-or-not.component';
import { FiltriComponent } from './filtri/filtri.component';
import { ChiudeSpotComponent } from './chiude-spot/chiude-spot.component';
import { CookiesInfoComponent } from './cookies-info/cookies-info.component';
import { HeaderYollyComponent } from './header-yolly/header-yolly.component';
import { SharedTranslateModule } from './shared-translate/shared-translate.module';
import { AvisoInicialComponent } from './aviso-inicial/aviso-inicial.component';



@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    NgbModule,
    FormsModule,
    SharedTranslateModule
    
  ],
  declarations: [
    NavbarComponent,
    OverloadComponent,
    SpotcardComponent,
    FooterComponent,
    ValidatorPasswordComponent,
    SpotDetailsComponent,
    ModalYolliComponent,
    InfoConnectionComponent,
    MessagesComponent,
    FollowOrNotComponent,
    FiltriComponent,
    ChiudeSpotComponent,
    CookiesInfoComponent,
    HeaderYollyComponent,
    AvisoInicialComponent,
  ],
  exports: [
    NavbarComponent,
    OverloadComponent,
    SpotcardComponent,
    FooterComponent,
    ValidatorPasswordComponent,
    SpotDetailsComponent,
    InfoConnectionComponent,
    FiltriComponent,
    ChiudeSpotComponent,
    CookiesInfoComponent,
    HeaderYollyComponent,
    AvisoInicialComponent
  ]
})

export class ComponentsModule { }
