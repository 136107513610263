import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { GeneralService } from '../../services/general.service';
import { SpotsService } from './../../services/spots.service';
import { Ricupero, ResetPaswword, LoginUser } from './../../components/models/user-data.model';
import { ValidatorPasswordService } from './../../components/validator-password/validator-password.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  private sub: any;
  public errorForm: boolean = false;
  public resultRes: boolean;
  public messageRes: string = '';
  public model = new Ricupero('');
  public modelLogin = new LoginUser('', '');
  public codiceInviato = false;
  public modelReset = new ResetPaswword('', '')

  constructor(
    public generalService: GeneralService,
    public spotsService: SpotsService,
    private router: Router,
    public validatorPasswordService: ValidatorPasswordService,
    private route: ActivatedRoute

  ) {

    this.generalService.positionPage = 'reset';
  }

  ngOnInit() {

    this.sub = this.route.paramMap.subscribe(params => {

     // console.log('parametri ricevuti ', params);
     // console.log(params.get('code'));
      this.modelReset.code = params.get('code');

    });

    let body = document.getElementsByTagName("body")[0];
    body.classList.add("bg-walki");

  }

  ngOnDestroy() {
    let body = document.getElementsByTagName("body")[0];
    body.classList.remove("bg-walki");
  }

  controlInvioReset() {

    let validForm = this.validatorPasswordService.getAndSetValidatedData().validPassword;

    if (!validForm) { } else {
      this.modelReset.password = this.validatorPasswordService.newPassword;
      this.invioResetEfectivo();
    }

    this.hideAlertError();

  }


  invioResetEfectivo() {

    if (!this.generalService.isConnect) { return; }

    this.generalService.loading = true;

      this.spotsService.resetPassword(this.modelReset).subscribe(data => {

        this.modelLogin.password = this.modelReset.password;

        if (data['success']) {

          this.modelLogin.username =data['username'];

          this.generalService.runToast(data['success'], data['message']);


          this.spotsService.authenticate(this.modelLogin).subscribe(res => {

            //if auth is ok: take the token
            if (res['success']) {

              //set localstorage and local variable token
              localStorage.setItem('token', res['token']);
              localStorage.setItem('certificateUser', 'true');

              //set Storage
              this.spotsService.setUserDetails(true);

              //hide overload
              this.generalService.loading = false;
              this.router.navigate(['/user-profile', {}]);


            } else {

              this.generalService.loading = false;
              this.showAlertError(res['message']);
            }

          }, error => {

            this.generalService.loading = false;
            this.generalService.crashServer(error)
          });

        } else {

          //hide overload and show error message
          this.generalService.loading = false;
          this.showAlertError(data['message']);
        }
      },
        error => {

          //hide overload
          this.generalService.loading = false;
          this.generalService.crashServer(error)

        });
    
  }


  // Show Alert And set Message
  showAlertError(message) {

    this.messageRes = message;
    this.errorForm = true;
  }

  // Hide Alert And empty Message
  hideAlertError() {

    this.errorForm = false;
    this.messageRes = '';
  }


}
