import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { GeneralService } from '../../services/general.service';
import { SpotsService } from './../../services/spots.service';
import { ValidatorPasswordService } from './../../components/validator-password/validator-password.service';
import { NewUser } from './../../components/models/user-data.model';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})

export class RegisterComponent implements OnInit, OnDestroy {

  //Show or hide the alert form error message
  public errorForm: boolean = false;

  //pick up the result and message from the server and UI control
  public resultRes: boolean;
  public messageRes: string = '';
  public errorFormRequiredName = false;

  //Model NewUser
  model = new NewUser('', '');

  //Pasword Repeat, outside Model
  public repitePass = '';

  constructor(
    public generalService: GeneralService,
    public spotsService: SpotsService,
    private router: Router,
    public validatorPasswordService: ValidatorPasswordService

  ) {
    this.generalService.positionPage = 'register';
  }

  ngOnInit() {

    let body = document.getElementsByTagName("body")[0];
    body.classList.add("bg-walki");
  }

  ngOnDestroy() {

    let body = document.getElementsByTagName("body")[0];
    body.classList.remove("bg-walki");
  }

  registraUtente() {

    let mensajes = this.validatorPasswordService.getAndSetValidatedData().errors;
    let validForm = this.validatorPasswordService.getAndSetValidatedData().validPassword;
    let username = this.model.username.replace(/(^\s*)|(\s*$)/g, "");

    if (username == '') {

      mensajes.push('Scrivi un nome');
      this.errorFormRequiredName = true;
      validForm = false;

    }

    if (!validForm) { } else {
      this.model.password = this.validatorPasswordService.newPassword;
      this.okregistraUtente();
    }

    this.hideAlertError();

  }

  okregistraUtente() {

    if (!this.generalService.isConnect) { return; }
    //show overload
    this.generalService.loading = true;

    //OK ==== Send New User
    this.spotsService.registerProccess(this.model).subscribe(data => {


      //if result is ok : call auth
      if (data['success']) {

        this.spotsService.authenticate(this.model).subscribe(res => {


          //if auth is ok: take the token
          if (res['success']) {

            //set localstorage and local variable token
            localStorage.setItem('token', res['token']);
            localStorage.setItem('certificateUser', 'true');

            //set Storage
            this.spotsService.setUserDetails(false);

            //hide overload
            this.generalService.loading = false;
            this.router.navigate(['/user-profile', {}]);


          } else {

            this.generalService.loading = false;
            this.showAlertError(res['message']);
          }

        }, error => {

          this.generalService.loading = false;
          this.generalService.crashServer(error)
        });

      } else {

        //hide overload and show error message
        this.generalService.loading = false;
        this.showAlertError(data['message']);
      }
    },
      error => {

        //hide overload
        this.generalService.loading = false;
        this.generalService.crashServer(error)

      });
  }

  // Show Alert And set Message
  showAlertError(message) {

    this.messageRes = message;
    this.errorForm = true;
  }

  // Hide Alert And empty Message
  hideAlertError() {

    this.errorForm = false;
    this.messageRes = '';
  }


}

