import { Component, OnInit, Input} from '@angular/core';
import { SpotsService } from './../../services/spots.service';
import { GeneralService } from './../../services/general.service'

@Component({
  selector: 'app-follow-or-not',
  templateUrl: './follow-or-not.component.html',
  styleUrls: ['./follow-or-not.component.css']
})

export class FollowOrNotComponent implements OnInit {

  @Input() spot;

  constructor(
    public spotsService: SpotsService,
    public generalService: GeneralService,
  ) { }

  ngOnInit(): void {}
  
  followTogg() {

    (this.spotsService.dataUser.certificateUser)? this.spotsService.followmeOrNot(this.spot._id, this.spot.currentUserisFollower) : this.generalService.msgLoginOrRegister(true,this.generalService.words_traslate.SIGUEESTAPUBLICACION)

  }

}
