import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SpotsService } from './../../services/spots.service';
import { GeneralService } from './../../services/general.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-chiude-spot',
  templateUrl: './chiude-spot.component.html',
  styleUrls: ['./chiude-spot.component.css']
})
export class ChiudeSpotComponent implements OnInit {

  @Input() spot;
  risoluzione = '';

  constructor(
    public activeModal: NgbActiveModal,
    public spotsService: SpotsService,
    public generalService: GeneralService,
    public ngbModal: NgbModal,
  ) { }

  ngOnInit(): void {
    // console.log( this.ngbModal.hasOpenModals())

  }

  close() {
    this.activeModal.close();
  }

  chiudeSpot() {

    if (confirm(this.generalService.words_traslate.QUERESCERRARPUBLICACION)) {

      let body = {
        'risoluzione': this.risoluzione
      }

      this.spotsService.chiudeSpot(this.spot._id, body).subscribe(data => {

        //console.log('response de chiudiSpot: ', data)
        this.generalService.loading = false;
        this.generalService.runToast(data['success'], data['message']);

        if (data['success']) {

          this.spotsService.deleteSpotInList(this.spot._id);
          let spotAggiornadoC = data['spot'];
          this.spotsService.newChiusiInList(spotAggiornadoC)
          this.activeModal.close();
        }

      }, error => {

        this.generalService.loading = false;
        this.generalService.crashServer(error);

      });

    }

  }

}
