import { Component, OnInit, OnDestroy } from '@angular/core';
import { GeneralService } from '../../services/general.service'; //== General service
import { SpotsService } from './../../services/spots.service';
import { NgbModal, NgbActiveModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { FiltriComponent } from './../../components/filtri/filtri.component';
import { Router } from '@angular/router';
import { ModSpotManipulationComponent } from './../../components/mod-spot-manipulation/mod-spot-manipulation.component';
import { SpotDetailsComponent } from './../../components/spot-details/spot-details.component';
import { Subscription } from 'rxjs';  // Importamos el objeto Subscription de rxjs
import { map } from 'rxjs/operators';
import MarkerClusterer from '@googlemaps/markerclustererplus';

declare const google: any, popup, Popup;

const svgMarkerPersi = "http://maps.google.com/mapfiles/ms/icons/red-dot.png";
const svgMarkerTrovati = "http://maps.google.com/mapfiles/ms/icons/pink-dot.png";
const svgMarkerAdozione = "http://maps.google.com/mapfiles/ms/icons/purple-dot.png";

@Component({
  selector: 'app-maps',
  templateUrl: './maps.component.html',
  styleUrls: ['./maps.component.scss']
})

export class MapsComponent implements OnInit, OnDestroy {

  public isCollapsed = true;
  public selected_id: Subscription;
  public markersInBound = 0;
  public circle = new google.maps.Circle();
  public markers: google.maps.Marker[] = [];
  public map;
  public currentInfoWindows;

  public infoWindow = new google.maps.InfoWindow({
    content: ''
  });

  public _Cluster;

  constructor(
    public generalService: GeneralService,
    public spotsService: SpotsService,
    private modalService: NgbModal,
    public router: Router

  ) {
    this.generalService.positionPage = 'mappa'
  }

  ngOnInit() {

    if (localStorage.getItem('spotListInBound_S')) {

      this.spotsService.spotListInBound = JSON.parse(localStorage.getItem('spotListInBound_S'))
    }

    document.getElementById("map-canvas").style.height = window.innerHeight - 50 + "px";
    this.spotsService.set_idselectedForMarker('0')

    let mapf = document.getElementById('map-canvas');
    this.infoWindow.setOptions({ maxWidth: 450 });

    let isSetZoom = 12;

    if (localStorage.getItem('zoomMap')) {
      isSetZoom = Number(localStorage.getItem('zoomMap'))
    }

    var mapOptions = {
      zoom: isSetZoom,
      scrollwheel: false,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      gestureHandling: "greedy",
      mapTypeControl: true,
      mapTypeControlOptions: {
        style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
        mapTypeIds: ["roadmap", "terrain"],
      },
    }

    // if position save 
    this.map = new google.maps.Map(mapf, mapOptions);

    if (localStorage.getItem('spotyLat') && localStorage.getItem('spotyLng')) {

      this.generalService.myLat = Number(localStorage.getItem('spotyLat'));
      this.generalService.myLng = Number(localStorage.getItem('spotyLng'));

      let pos = {
        lat: Number(localStorage.getItem('spotyLat')),
        lng: Number(localStorage.getItem('spotyLng')),
      };

      this.map.setCenter(pos);
      this.setRadiusMap()

    } else {

     /* this.generalService.myLat = 44.414165;
      this.generalService.myLng = 8.942184;

      let pos = {
        lat: 44.414165,
        lng: 8.942184,
      };*/

      this.generalService.myLat = Number(this.generalService.myLat);
      this.generalService.myLng = Number(this.generalService.myLng);

      let pos = {
        lat:this.generalService.myLat,
        lng:this.generalService.myLng,
      };

      this.map.setCenter(pos);
      this.setRadiusMap()

    }

    this.map.addListener("dragend", () => {
      this.generalService.skipSpotList = 0;
      this.setRadiusMap()

    });

    this.map.addListener("zoom_changed", () => {
      this.setRadiusMap()
    });

    this.map.addListener("bounds_changed", () => {
      this.setRadiusMap()
    });
    this.setRadiusMap();
    this.setMarker();


  }

  ngOnDestroy() {
    this.generalService.skipSpotList = 0;
  }

  setMarker() {

    if (!this.generalService.isConnect) { return; }

    if (this.spotsService.filterSelected != 'tutti') {

      this.setMarkerInMap(this.spotsService.listSelected);
      return;
    }

    let loc = {
      'userLat': this.generalService.myLat,
      'userLong': this.generalService.myLng,
      'radius': '1250000000000000000000000000000'
    }

    if (this.spotsService.filterSelected == 'tutti') {

      /* if (localStorage.getItem('markers')) {
           let spotMap = JSON.parse(localStorage.getItem('markers'));
           this.setMarkerInMap(spotMap);
           return;
         }*/

      this.generalService.viewNabIndeterminate = true;
      this.spotsService.getSpotsMapa(loc).subscribe(data => {

        if (data['success']) {

          this.spotsService.server_spotMap = data['spots'];
          localStorage.setItem('spotsMapa_S', JSON.stringify(data['spots']));
          this.setMarkerInMap(data['spots']);

        } else {
          this.generalService.viewNabIndeterminate = false;
        }

      }, error => {
        this.generalService.viewNabIndeterminate = false;
      });

    }
    

  }

  setRadiusMap() {

    if (this.map.getBounds()) {

      this.circle.setMap(null);

      var latNEValue = this.map.getBounds().getNorthEast().lat();
      var longNEValue = this.map.getBounds().getNorthEast().lng();
      var latSWValue = this.map.getBounds().getSouthWest().lat();
      var longSWValue = this.map.getBounds().getSouthWest().lng();

      var location1 = new google.maps.LatLng(latNEValue, longNEValue);
      var location2 = new google.maps.LatLng(latSWValue, longSWValue);
      var distance = google.maps.geometry.spherical.computeDistanceBetween(location1, location2);
      var radius = distance / 2;

      this.circle.setRadius(radius);
      this.generalService.radiusMap = radius / 1000;
      let varRadius = radius / 1000;

      let zoom = this.map.getZoom();
      this.generalService.setVarPosition(this.map.getCenter().lat(), this.map.getCenter().lng(), varRadius, zoom)
      this.generalService.skipSpotList = 0;

      //console.log(this._Cluster)
     // console.log(this.markers)

      let k = 0;

      this.spotsService.spotListInBound = [];

      for (var i = this.markers.length, bounds = this.map.getBounds(); i--;) {
        if (bounds.contains(this.markers[i].getPosition())) {
          // code for showing your object
          k++;
          this.spotsService.spotListInBound.push(this.markers[i].get('_id'))
        }
      }
    //  console.log(k)
      this.spotsService.createArrayBound(this.spotsService.spotListInBound);
      this.markersInBound = k;

    }
  }


  openMarkerid() {

    this.selected_id = this.spotsService.get_idselectedForMarker().subscribe(
      _id => {

        if (_id != '0') {

          let _idSelected = _id

          for (var i = 0; i < this.markers.length; i++) {

            if (this.markers[i].get('_id') == _idSelected) {
              google.maps.event.trigger(this.markers[i], "click");
            }

          }
        }

      });

  }




  getPath(tag: string) {

    tag = tag.toLowerCase();

    switch (tag) {

      case 'persi':
        return svgMarkerPersi;
        break;

      case 'trovati':
        return svgMarkerTrovati;
        break;

      case 'in adozione':
        return svgMarkerAdozione;
        break;

      default:
        return svgMarkerAdozione;
    }

  }

  setMyPos() {

    if (!this.generalService.navigatorIsOk) {
      alert(this.generalService.words_traslate.INFOGEONOSOPORTADA);
      return;
    }

    if (!this.generalService.acceptGeo) {
      alert(this.generalService.words_traslate.ACTIVAGEOLO);
      return;
    }

    let infoWindow = new google.maps.InfoWindow({
      content: ''
    });


    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {

          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };

          infoWindow.setPosition(pos);
          infoWindow.setContent(this.generalService.words_traslate.ESTASACA);
          infoWindow.open(this.map);

          this.map.setCenter(pos);

          this.generalService.skipSpotList = 0;

          this.setRadiusMap()


          setTimeout(() => {
            infoWindow.close();
          }, 3000);
          this.setRadiusMap()
        },
        () => {
          this.handleLocationError(true, infoWindow, this.map.getCenter());
        }
      );
    } else {
      // Browser doesn't support Geolocation
      this.handleLocationError(false, infoWindow, this.map.getCenter());
    }
  }

  handleLocationError(browserHasGeolocation: boolean, infoWindow: google.maps.InfoWindow, pos: google.maps.LatLng) {
    infoWindow.setPosition(pos);
    infoWindow.setContent(
      browserHasGeolocation
        ? "Error: The Geolocation service failed."
        : "Error: Your browser doesn't support geolocation."
    );
  }

  openFormNuovoSpot() {

    let modal = this.modalService.open(ModSpotManipulationComponent, { size: 'lg', backdrop: 'static' });

    (<ModSpotManipulationComponent>modal.componentInstance).type = 'new';

    modal.result.then((result) => {

      let pos = {
        lat: Number(localStorage.getItem('spotyLat')),
        lng: Number(localStorage.getItem('spotyLng')),
      };

      // this._Cluster.clearMarkers();
      // this.setMarkerInMap(this.spotsService.server_spotMap);

      this.map.panTo(pos);
      this.map.setZoom(5);
      this.map.setCenter(pos);
      this.setMarker()

      this.generalService.skipSpotList = 0;
      this.setRadiusMap()
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        location.reload();
      });
    }, (reason) => {
      //console.log('reason', reason)
    });
  }

  openEditFormSpot() {

    //   console.log('spot pasado a modal', this.currentInfoWindows)

    let modal = this.modalService.open(ModSpotManipulationComponent, { size: 'lg', backdrop: 'static' });

    (<ModSpotManipulationComponent>modal.componentInstance).type = 'edit';
    (<ModSpotManipulationComponent>modal.componentInstance).spot = this.spotsService.createSingleSpot(this.spotsService.dataUser.username, this.currentInfoWindows);

    modal.result.then((result) => {

      //  console.log('resultado al cierre del editor spot',result)

      let pos = {
        lat: Number(localStorage.getItem('spotyLat')),
        lng: Number(localStorage.getItem('spotyLng')),
      };

      this.map.panTo(pos);
      this.map.setZoom(15);
      this.map.setCenter(pos);
      this.setMarker()
      this.setRadiusMap()

      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        location.reload();
      });

    }, (reason) => {

      // console.log('reason',reason)
    });
  }

  viewDetails(showStatus){

    let modal = this.modalService.open(SpotDetailsComponent, { backdrop: 'static' });

    (<SpotDetailsComponent>modal.componentInstance).spot = this.spotsService.createSingleSpot(this.spotsService.dataUser.username, this.currentInfoWindows);
    (<SpotDetailsComponent>modal.componentInstance).id = this.currentInfoWindows._id;
    (<SpotDetailsComponent>modal.componentInstance).showStatus = showStatus;

    modal.result.then((result) => {
    }, (reason) => {

      for (let i = 0; i < this.markers.length; i++) {

        let idM = this.markers[i].get('_id')

        if (idM == this.currentInfoWindows._id) {
          this.infoWindow.open(this.map, this.markers[i]);
        }

      }
    });

  }

  apriFiltri() {

    let modal = this.modalService.open(FiltriComponent, {});

    modal.result.then((result) => {

      if (this.spotsService.filterSelected == 'tutti') {

        this._Cluster.clearMarkers();
        this.setMarkerInMap(this.spotsService.server_spotMap);

      } else {

        const arr1 = this.spotsService.server_spotMap.filter(d => d.tag.toLowerCase() == this.spotsService.filterSelected);
        this.spotsService.listSelected = arr1;
        this._Cluster.clearMarkers();
        this.setMarkerInMap(arr1);
        this.spotsService.composedSpotsFilterList();

      }
    }, (reason) => {
      // console.log('reason',reason)
    });
  }

  setMarkerInMap(markerServer) {

    let result = markerServer;

    this.markers = result.map((spot, i) => {

      const coords = result[i]['location'].coordinates;
      const latLng = new google.maps.LatLng(coords[1], coords[0]);
      const titolo = result[i]['titolo'];
      const spotImage = result[i]['spotImage'];
      const status = result[i]['status'];
      const tag = result[i]['tag']
      const tagtxt = this.generalService.traslate_tag(result[i]['tag']);
      const tagOfMarker = this.getPath(tag);
      const tagC = this.generalService.getClassTag(tag);
      const statusC = this.generalService.getClassStatus(status);

      let marker = new google.maps.Marker({
        position: latLng,
        _id: result[i]['_id'],
        map: this.map,
        animation: google.maps.Animation.DROP,
        title: titolo,
        spot: result[i],
       // icon: tagOfMarker,
       icon: {
        url: tagOfMarker 
      },
        tag: tag
      });

      const spotContent = '<div class="card"><div class="col-12" style="padding: 0;"><h2 style="font-size: 14px;margin-bottom: 5px;"><span class="badge badge-pill ' + tagC + '"  >' + tagtxt + '</span></h2></div><img src="' + spotImage + '"  class=" mt-0" style=" max-width:140px;"> <div class="card-body" style="padding: .5rem;"><h5 class="card-title" style="margin-bottom: 7px;">' + titolo + '</h5>  <button class="btn-outline-default btn-sm btn-block" id="view"><span><i class="bi bi-eye" style="margin: 6px;margin-bottom: 7px;"></i>'+this.generalService.words_traslate.DETALLES+'</span></button> </div> </div>'

      google.maps.event.addListener(marker, 'click', (evt) => {

        this.currentInfoWindows = result[i];
        this.infoWindow.setContent(spotContent);
        this.infoWindow.open(this.map, marker);

      })

      return marker;
    });

    google.maps.event.addListener(this.infoWindow, 'content_changed', (evt) => {
      this.spotsService.set_idselectedForMarker('0')
    })

    google.maps.event.addListener(this.infoWindow, 'position_changed', (evt) => {
      this.spotsService.set_idselectedForMarker('0')
    })

    google.maps.event.addListener(this.infoWindow, 'closeclick', (evt) => {
      this.spotsService.set_idselectedForMarker('0')
    })

    // Add a marker clusterer to manage the markers.
    this._Cluster = new MarkerClusterer(this.map, this.markers, {
      imagePath:
        "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
    });

    /*  for (let i = 0; i < this.markers.length; i++) {
        this.markers[i].setMap(null);
        this.markers.pop();
      }*/

    // trigger click button into infowindows
    google.maps.event.addListener(this.infoWindow, 'domready', (e) => {

      const contentBtn = document.getElementById('view');

      google.maps.event.addDomListenerOnce(contentBtn, 'click', () => {
        if (this.infoWindow) {
          this.infoWindow.close();
        }
        this.viewDetails(false);
      });
      // e.preventDefault(); 
    }, {
      //  passive: true
    });
    this.generalService.viewNabIndeterminate = false;
  }

}