import { Component, OnInit, OnDestroy } from '@angular/core';
import { ValidatorPasswordService } from './validator-password.service';
import { GeneralService } from './../../services/general.service';

@Component({
  selector: 'app-validator-password',
  templateUrl: './validator-password.component.html',
  styleUrls: ['./validator-password.component.css']
})

export class ValidatorPasswordComponent implements OnInit, OnDestroy {

  strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
  mediumRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})");

  public isActivepass: boolean = true;
  public isActiveRepeat: boolean = true;

  constructor(
    public validatorPasswordService: ValidatorPasswordService,
    public generalService: GeneralService,
  ) { }

  ngOnInit(): void {}

  ngOnDestroy() {

    this.validatorPasswordService.cleanForm();
    this.isActiveRepeat = true;
    this.isActivepass = true;

  }

  // When the user clicks on the password field, show the message box
  myInputonfocus() {
    this.validatorPasswordService.showErrorPasswordRequirements = true;
  }

  // When the user clicks outside of the password field, hide the message box
  myInputonblur() {
    //this.validatorPasswordService.showErrorPasswordRequirements = false;
  }

  // When the user starts to type something inside the password field
  evaluaPass() {

    this.validatorPasswordService.messageError = [];

    this.validatorPasswordService.showErrorPasswordRequirements = true;
    let myInput = document.getElementById("psw");
    let letter = document.getElementById("letter");
    let capital = document.getElementById("capital");
    let number = document.getElementById("number");
    let length = document.getElementById("length");

    let validator = true;

    // Validate lowercase letters
    var lowerCaseLetters = /[a-z]/g;
    if (this.validatorPasswordService.newPassword.match(lowerCaseLetters)) {
      letter.classList.remove("invalid");
      letter.classList.add("valid");
      
    } else {

      letter.classList.remove("valid");
      letter.classList.add("invalid");
      this.validatorPasswordService.messageError.push(this.generalService.words_traslate.FALTAMAYUSCULAPASS)
      validator = false;
    }

    // Validate capital letters
    var upperCaseLetters = /[A-Z]/g;
    if (this.validatorPasswordService.newPassword.match(upperCaseLetters)) {
      capital.classList.remove("invalid");
      capital.classList.add("valid");
    
    } else {

      capital.classList.remove("valid");
      capital.classList.add("invalid");
      this.validatorPasswordService.messageError.push(this.generalService.words_traslate.FALTAMINUCCULAPASS)
      validator = false;
    }

    var numbers = /[0-9]/g;
    if (this.validatorPasswordService.newPassword.match(numbers)) {

      number.classList.remove("invalid");
      number.classList.add("valid");

    } else {

      number.classList.remove("valid");
      number.classList.add("invalid");
      this.validatorPasswordService.messageError.push(this.generalService.words_traslate.FALTANUMEROPASS)
      validator = false;
    }

    if (this.validatorPasswordService.newPassword.length >= 8) {
      length.classList.remove("invalid");
      length.classList.add("valid");

    } else {

      length.classList.remove("valid");
      length.classList.add("invalid");
      validator = false;
      this.validatorPasswordService.messageError.push(this.generalService.words_traslate.OCHONUMEROSPASS)
    }

    this.strongfunction(this.validatorPasswordService.newPassword);
    this.validatorPasswordService.validPassword = validator ? true : false;
  }

  strongfunction(value) {

    let progress = document.getElementById("progressb");

    if (this.strongRegex.test(value)) {

      progress.classList.remove("bg-yellow");
      progress.classList.remove("bg-danger");
      progress.classList.add("bg-success");
      progress.style.width = '100%';

    } else if (this.mediumRegex.test(value)) {
      progress.classList.remove("bg-success");
      progress.classList.remove("bg-danger");
      progress.classList.add("bg-yellow");
      progress.style.width = '45%';

    } else {
      progress.classList.remove("bg-success");
      progress.classList.remove("bg-yellow");
      progress.classList.add("bg-danger");
      progress.style.width = '10%';
    }
  };

  getCoincidencePassword() {
    this.validatorPasswordService.showErrorrepeatPasswNoCoincidence = this.validatorPasswordService.newPassword == this.validatorPasswordService.repeatPassw ? false : true;

  }

  viewPassToog() {
    this.isActiveRepeat = this.isActiveRepeat ? false : true;
    this.isActivepass = this.isActiveRepeat ? false : true;
    }
}
