
import { Component, OnInit, Input, OnDestroy, AfterViewInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SpotsService } from './../../services/spots.service';
import { ModImageManipulationService } from './../mod-image-manipulation/mod-image-manipulation.service';
import { GeneralService } from './../../services/general.service';
import { Subscription } from 'rxjs';  // Importamos el objeto Subscription de rxjs
import { Router } from '@angular/router';
import { ChiudeSpotComponent } from './../chiude-spot/chiude-spot.component';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Spot } from './../models/spot-model.model';

@Component({
  selector: 'app-spot-details',
  templateUrl: './spot-details.component.html',
  styleUrls: ['./spot-details.component.css']
})
export class SpotDetailsComponent implements OnInit, OnDestroy {

  @Input() spot;
  @Input() id;
  @Input() showStatus;

  public mapG;
  public markers: google.maps.Marker[] = [];
  public comment: string = '';
  public isSpot = true;
  public definedisSpot = false;
  public details: Subscription;

  constructor(
    public activeModal: NgbActiveModal,
    public spotsService: SpotsService,
    public modImageManipulationService: ModImageManipulationService,
    private generalService: GeneralService,
    private router: Router,
    private modalService: NgbModal,

  ) { }


  ngOnInit() {

    this.spotsService.getSingleSpot(this.id).subscribe(data => {

      //   console.log('details', data)

      if (data['success']) {

        if (data['dettagliSpot'] == null) {

          this.isSpot = false;
          this.definedisSpot = true;
          this.spotsService.deleteSpotInList(this.id)
          return;

        }

        this.spotsService.lettoCommento(this.spot);
        this.spotsService.setSpotDetailsInView(this.spotsService.createSingleSpot(this.spotsService.username, data['dettagliSpot']));
        this.isSpot = true;
        this.definedisSpot = true;
        this.spotsService.refrehDetailsPostInLists(data['dettagliSpot']._id, data['dettagliSpot'], this.spotsService.createSingleSpot(this.spotsService.username, data['dettagliSpot']));
        this.listenDetails();


      }

    }, error => {
      this.generalService.crashServer(error)
    });

  }

  setMapinView() {

    let mapFrame = document.getElementById('map-canvas-new');

    var mapGOptions = {
      zoom: 12,
      scrollwheel: false,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
    }

    // if position save 
    this.mapG = new google.maps.Map(mapFrame, mapGOptions);

    const styles: Record<string, google.maps.MapTypeStyle[]> = {
      default: [],
      hide: [
        { featureType: "poi.business", stylers: [{ visibility: "off" }], },
        { featureType: "transit", elementType: "labels.icon", stylers: [{ visibility: "off" }], },
      ],
    };

    this.mapG.setOptions({ styles: styles["hide"] });

    //== Center Map and push marker if type == edit

    let pos = {
      lat: Number(this.spot.lat),
      lng: Number(this.spot.long),
    };

    let latLng = new google.maps.LatLng(Number(this.spot.lat), Number(this.spot.long));
    this.mapG.setCenter(pos);

    const marker = new google.maps.Marker({
      position: latLng,
      map: this.mapG,
    });

    this.markers.push(marker);
  }

  private listenDetails(): void {

    this.details = this.spotsService.getSpotDetailsInView().subscribe(
      detalle => {
        this.spot = detalle;

        if (this.spot._id != '') {
          this.setMapinView();
        }

      });
  }

  ngOnDestroy(): void {
    this.spotsService.setDefaultSpotDetailsInView();
  }

  close() {
    this.activeModal.close();
    this.spotsService.setDefaultSpotDetailsInView();


  }

  sendComm() {
    this.spotsService.sendComment(this.id, this.comment);
    this.comment = '';
  }

  deleteComment(id) {

    if (confirm(this.generalService.words_traslate.QUIERESELIMINARMENSAJE)) {

      this.generalService.loading = true;
      this.spotsService.deleteComment(this.id, id);

    }
  }

  setMyPos() {

    if (!this.generalService.navigatorIsOk) {
      alert(this.generalService.words_traslate.INFOGEONOSOPORTADA);
      return;
    }

    if (!this.generalService.acceptGeo) {
      alert(this.generalService.words_traslate.ACTIVAGEOLO);
      return;
    }

    let infoWindow = new google.maps.InfoWindow({
      content: ''
    });

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {

          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };

          infoWindow.setPosition(pos);
          infoWindow.setContent(this.generalService.words_traslate.ESTASACA);
          infoWindow.open(this.mapG);
          this.mapG.setCenter(pos);

          setTimeout(() => {
            infoWindow.close();
          }, 3000);

        },
        () => {
          this.handleLocationError(true, infoWindow, this.mapG.getCenter());
        }
      );
    } else {
      // Browser doesn't support Geolocation
      this.handleLocationError(false, infoWindow, this.mapG.getCenter());
    }
  }

  handleLocationError(browserHasGeolocation: boolean, infoWindow: google.maps.InfoWindow, pos: google.maps.LatLng) {
    infoWindow.setPosition(pos);
    infoWindow.setContent(
      browserHasGeolocation
        ? "Error: The Geolocation service failed."
        : "Error: Your browser doesn't support geolocation."
    );
  }

  onImgError(event) {
    event.target.src = './assets/img/default/null.jpg'
  }

  toLogin() {

    this.activeModal.close();
    this.router.navigate(['/login', {}]);

  }
  toregister() {
    this.activeModal.close();
    this.router.navigate(['/register', {}]);
  }

  apriPerChiudere() {
    //  console.log('reason')
    let modal = this.modalService.open(ChiudeSpotComponent, {});
    (<ChiudeSpotComponent>modal.componentInstance).spot = this.spot;
    modal.result.then((result) => {

    }, (reason) => {
      // console.log('reason',reason)
    });

  }
}
