import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SpotsService } from './../../../services/spots.service';
import { GeneralService } from '../../../services/general.service';
import { ValidatorPasswordService } from './../../../components/validator-password/validator-password.service';
import { Router } from '@angular/router';
import { ModelDataForChangeUserProfile, LoginUser, ModelChangeUserPassword, ModelValidatorPassword } from './../../../components/models/user-data.model';

@Component({
  selector: 'app-modal-edit-user-data',
  templateUrl: './modal-edit-user-data.component.html',
  styleUrls: ['./modal-edit-user-data.component.css']
})

export class ModalEditUserDataComponent implements OnInit {

  public modelDataForChangeUserProfile = new ModelDataForChangeUserProfile('', false);
  public modelChangeUserPassword = new ModelChangeUserPassword('');
  public modelValidatorPassword = new ModelValidatorPassword('', '', '');

  public miniLoading: boolean = true;
  public user: LoginUser;

  public errorFormRequiredPassCorrente = false;
  public errorFormPassCorrente = false;
  public messageErrorFormPassCorrente: string = '';

  public model = new LoginUser('', '');
  public isActiveOldpass: boolean = true;
  public active;

  constructor(

    public activeModal: NgbActiveModal,
    public spotsService: SpotsService,
    public generalService: GeneralService,
    public validatorPasswordService: ValidatorPasswordService,
    private router: Router

  ) { }

  ngOnInit(): void {

    if (!this.spotsService.couldBeLogged()) {

      this.router.navigate(['/login', {}]);

    } else {

      this.getDataUserEdition();
      this.errorFormRequiredPassCorrente = false;

    }

  }


  getDataUserEdition() {

    this.spotsService.userDetails().subscribe(data => {

      if (data['success']) {

        // INIT Model for Edit username and Email
        this.modelDataForChangeUserProfile = {
          newEmail: (data['userDetails']['email'] == 'Configura e-mail') ? '' : data['userDetails']['email'],
          emailValidated: data['userDetails']['mailValidated']
        }

        this.miniLoading = false;

      } else {

        this.miniLoading = false;
        this.generalService.runToast(data['success'], data['message']);
      }

    }, error => {

      this.miniLoading = false;
      this.generalService.crashServer(error)
    });
  }

  //== Edit Email
  editInformationUser() {

    let stringEmail = this.modelDataForChangeUserProfile.newEmail.replace(/(^\s*)|(\s*$)/g, "");

    if (stringEmail == '') {

      if (confirm(this.generalService.words_traslate.QUERESELIMINAREMAIL)) {
        this.modelDataForChangeUserProfile.newEmail = '';
        this.goEditInformationUser();
      }

    } else {

      let myInput = document.getElementById("input-email");
      if (myInput.classList.contains('ng-invalid')) {

      } else {

        this.goEditInformationUser();
      }
    }
  }

  goEditInformationUser() {

    this.generalService.loading = true;
    this.spotsService.updateUserProfile(this.modelDataForChangeUserProfile).subscribe(data => {

      this.generalService.loading = false;
      this.spotsService.setUserDetails(false)
      this.generalService.runToast(data['success'], data['message']);

    }, error => {

      this.generalService.loading = false;
      this.generalService.crashServer(error)

    });

  }

  editPasswordUser() {

    let mensajes = this.validatorPasswordService.getAndSetValidatedData().errors;
    let validForm = this.validatorPasswordService.getAndSetValidatedData().validPassword;

    let old = this.modelValidatorPassword.oldPassword.replace(/(^\s*)|(\s*$)/g, "");

    if (old == '') {

      mensajes.push(this.generalService.words_traslate.ESCRIBEPASS);
      this.errorFormRequiredPassCorrente = true;
      validForm = false;

    } else {

      this.generalService.loading = true;
      this.model.password = this.modelValidatorPassword.oldPassword;
      this.model.username = this.spotsService.dataUser.username;

      this.spotsService.authenticate(this.model).subscribe(data => {

        if (data['success']) {

          localStorage.setItem('token', data['token']);

          if (!validForm) { } else {
            this.okEditPasswordUser();
          }

        } else {

          this.generalService.loading = false;
          this.errorFormPassCorrente = true;
          this.messageErrorFormPassCorrente = data['message'];
          validForm = false;

        }

      }, error => {

        validForm = false;
        this.generalService.loading = false;
        this.generalService.crashServer(error)
      });

    }

  }

  okEditPasswordUser() {

    this.generalService.loading = true;
    this.modelChangeUserPassword.newPassword = this.validatorPasswordService.newPassword;

    this.spotsService.updateUserPass(this.modelChangeUserPassword).subscribe(data => {

      if (data['success']) {

        this.user = {
          username: '',
          password: this.validatorPasswordService.newPassword
        }

        this.validatorPasswordService.cleanForm();
        this.modelValidatorPassword.oldPassword = '';
      }

      this.generalService.loading = false;
      this.generalService.runToast(data['success'], data['message']);

    }, error => {

      this.generalService.loading = false;
      this.generalService.crashServer(error)

    });

  }

  close() {
    this.activeModal.close();
  }

  viewOldPassToog() {
    this.isActiveOldpass = this.isActiveOldpass ? false : true;
  }
  validaMail() { }

  elimina() {

    if (confirm(this.generalService.words_traslate.SEISICURO)) {
     
      this.spotsService.eliminaCuenta().subscribe(data => {

        this.generalService.loading = false;
        this.generalService.runToast(true, this.generalService.words_traslate.CHAU);

        if (data['success']) {


          this.spotsService.destroyprofileUser();
          this.activeModal.dismiss('Cross click')
          this.router.navigate(['/mappa']);

        }

      }, error => {

        this.generalService.loading = false;
        this.generalService.crashServer(error);

      });
    }
  }
}