import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GeneralService } from '../../services/general.service'; //== General service
import { SpotsService } from './../../services/spots.service'; //== Data User profile service


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})

export class NavbarComponent implements OnInit {

  public isCollapsed = true;
  public dataForViewNav;

  constructor(

    private router: Router,
    public generalService: GeneralService,
    public spotsService: SpotsService,
    
  ) {
   
   }

  ngOnInit() {

    this.listenDataForNav();
    this.router.events.subscribe((event) => {
      this.isCollapsed = true;
    });


  }

  listenDataForNav() {

    this.spotsService.getDataForNav().subscribe(
      newData => {
        this.dataForViewNav = newData;
      });
  }

  onImgError(event) {
    event.target.src = this.generalService.defAvatarUrl;
  }




}
