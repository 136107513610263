import { Component, OnInit } from '@angular/core';
import { GeneralService } from '../../services/general.service'; //== General service
import { SpotsService } from '../../services/spots.service'; //== General service
import { NgbModal, NgbActiveModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ModSpotManipulationComponent } from './../../components/mod-spot-manipulation/mod-spot-manipulation.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  constructor(
    public generalService: GeneralService,
    public spotsService: SpotsService,
    private modalService: NgbModal,
  ) {

    this.generalService.positionPage = 'list'

  }

  ngOnInit() {
    //this.generalService.loading=true;
    this.generalService.skipSpotList = 0;
    this.spotsService.setListSpots()
    //this.generalService.msgLoginOrRegister(true)
    // this.generalService.msgNotFound(true)
   
  }

  onScroll() {

    this.generalService.skipSpotList = this.generalService.skipSpotList + this.generalService.limitSpotList;

  //  console.log('scrolledDown!!', this.generalService.limitSpotList + ' ' + this.generalService.skipSpotList);

    this.spotsService.setListSpots()

  }

  onUp() {
  //  console.log('scrolledUp!!');
  }

  openFormNuovoSpot() {

    let modal = this.modalService.open(ModSpotManipulationComponent, { size: 'lg', backdrop: 'static' });

    (<ModSpotManipulationComponent>modal.componentInstance).type = 'new';
    modal.result.then((result) => {
   //   console.log('result', result)
      if (result.success) {
        this.spotsService.setListSpots();
        // this.spotsService.setListSpots_prov();
      }
    }, (reason) => {

    //  console.log('reason', reason)




    });
  }

  /*
  sessionStorage.user = JSON.stringify({name: "John"});

  // en algún momento más tarde
  let user = JSON.parse( sessionStorage.user );
  alert( user.name ); // John
  */

}
