import { Injectable, OnInit } from '@angular/core';
import { Observable, Observer, fromEvent, merge, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})

export class GeneralService {

  public words_traslate = {
    "SITIO": "",
    "EMAIL": "",
    "EMAILVERIFICADA": "",
    "VUELVEALSITIO": "",
    "QUIERESELIMINARMENSAJE": "",
    "QUERESCERRARPUBLICACION": "",
    "INFOGEONOSOPORTADA": "",
    "ACTIVAGEOLO": "",
    "INCLUYEIMAGENENLAPUBLICACION": "",
    "ESCRIBETITULO": "",
    "ESCRIBETEXTO": "",
    "SELEZIONACATEGORIA": "",
    "LOCALIZAPOST": "",
    "NECESITAMOSMASINFO": "",
    "ELIMINARETERNAMENTE": "",
    "FALTAMAYUSCULAPASS": "",
    "FALTAMINUCCULAPASS": "",
    "FALTANUMEROPASS": "",
    "OCHONUMEROSPASS": "",
    "FALTANCAMPOS": "",
    "QUERESELIMINAREMAIL": "",
    "CHAU": "",
    "CARGAIMAGEN": "",
    "SIGUEESTAPUBLICACION": "",
    "ESCRIBEUSERNAME": "",
    "ESCRIBEPASS": "",
    "SEISICURO": "",
    "PUBLICADOPOR": "",
    "IMPOSIBLECARGARFILE": "",
    "NOPUDIMOSCAMBIARIMAGENDEPERFIL": "",
    "ENERO": "",
    "FEBRERO": "",
    "MARZO": "",
    "ABRIL": "",
    "MAYO": "",
    "JUNIO": "",
    "JULIO": "",
    "AGOSTO": "",
    "SEPTIEMBRE": "",
    "OCTUBRE": "",
    "NOVIEMBRE": "",
    "DICIEMBRE": "",
    "DE": "",
    "ALAS": "",
    "ESTASACA": "",
    "PERDIDOS": "",
    "ENCONTRADOS": "",
    "ENADOPCION": "",
    "ENTRANSITO": "",
    "ABIERTO": "",
    "CERRADO": "",
    "ENTRA": "",
    "REGISTRATE": "",
    "PARAENVIARUNCOMENTARIO": "",
    "PARAHACERLAACCION": "",
    "QUIZAANUNCIOELIMINADO":"",
    "ESCRIBEUNCOMENTARIO": "",
    "ENVIARCOMENTARIO": "",
    "DETALLES" : "",
    "IMAGENLOGO": "",
    "IMAGENGATO": ""
  }
  public LatLang = (navigator.language == 'es') ? '-34.46197833064051' : '44.414165';
  public LngLang = (navigator.language == 'es') ? '-60.388650881264255' : '8.942184';
  public loading: boolean = false;
  public viewNabIndeterminate: boolean = false;
  public acceptGeo: boolean = false;
  public navigatorIsOk: boolean = false;
  public isConnect = true;
  public isUpdate = true;
  public loadingConecction = false;
  public userClosedInfoCookie = false;
  public infocookieView = true;
  public emailPattern = "[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$";
  public positionPage: string = '';
  public defAvatarUrl = './assets/img/default/avatar.png';
  public limitSpotList: number = 10;
  public skipSpotList: number = 0;
  public radiusMap: number = 3;
  public zoomMap: number = (navigator.language == 'es') ? 7 : 12;
  public myLat: number = Number(this.searchKeyInLocalstorage('spotyLat', this.LatLang));
  public myLng: number = Number(this.searchKeyInLocalstorage('spotyLng', this.LngLang));
  public chiusuraEnModal = false;
  public timeLocal = false;







  /**
   * @param toast Toast Service (prevent error with providers: [ ToastrService]) in app.module
   */
  constructor(
    public toast: ToastrService
  ) { }


  /**
   * @returns {Observable} Indicator - if there is an internet connection or not
   */

  public createOnline$(): Observable<any> {
    return merge<boolean>(
      fromEvent(window, 'offline').pipe(map(() => false)),
      fromEvent(window, 'online').pipe(map(() => true)),
      new Observable((sub: Observer<boolean>) => {
        sub.next(navigator.onLine);
        sub.complete();
      }));
  }

  /*
    public capitalize(stringToCapitalize: string): string {
  
      if (typeof stringToCapitalize !== 'string') return '';
      return stringToCapitalize.charAt(0).toUpperCase() + stringToCapitalize.slice(1);
  
    }*/


  /**
   * @param {string} status Status of spots - Evaluated only if "Aperto"
   * @returns {string} Style class of Badge status
   */

  public getClassStatus(status: string): string {
    return (status == 'aperto') ? 'badge-open' : 'badge-closed';
  }


  /**
  * @param {string} tag Tag of spots 
  * @returns {string} Style class of Badge tag
  */

  public getClassTag(tag: string): string {
    console.log(this.myLat)
    let clase = 'badge-light';
    var taglower = tag.toLowerCase();

    if (taglower) {

      if (taglower == 'persi') { clase = 'badge-persi'; }
      if (taglower == 'trovati') { clase = 'badge-trovati'; }
      if (taglower == 'in adozione') { clase = 'badge-adozione'; }
      if (taglower == 'veterinari') { clase = 'badge-veterinari'; }
      if (taglower == 'negozi specializzati') { clase = 'badge-negozi'; }
      if (taglower == 'animal sitter') { clase = 'badge-sitter'; }
      if (taglower == 'in transito') { clase = 'badge-sitter'; }

    }
    return clase;

  }

  public traslate_tag(tag) {

    let taglower = tag.toLowerCase();
    let tag_traslated = tag;

    if (taglower == 'persi') { tag_traslated = this.words_traslate.PERDIDOS; }
    if (taglower == 'trovati') { tag_traslated = this.words_traslate.ENCONTRADOS; }
    if (taglower == 'in adozione') { tag_traslated = this.words_traslate.ENADOPCION; }
    /*   if (taglower == 'veterinari') { tag_traslated = 'badge-veterinari'; }
       if (taglower == 'negozi specializzati') { tag_traslated = 'badge-negozi'; }
       if (taglower == 'animal sitter') { tag_traslated = 'badge-sitter'; }*/
    if (taglower == 'in transito') { tag_traslated = this.words_traslate.ENTRANSITO; }

    return tag_traslated;
  }

  public traslate_stato(stato) {

    let statolower = stato.toLowerCase();
    let stato_traslated = stato;

    if (statolower == 'aperto') {
      stato_traslated = this.words_traslate.ABIERTO;

    } else {
      stato_traslated = this.words_traslate.CERRADO;

    }



    return stato_traslated;
  }


  /**
   * Search in localstorage, if not exist return defaultValue
   * @param {string} searchKey key for Search in localstorage
   * @param {string} defaultValue Value to return if localStorage not exist 
   * @returns {string} value of localstorage if exist or defaultValue
   */
  public searchKeyInLocalstorage(searchKey: string, defaultValue: string): string {

    if (localStorage.getItem(searchKey)) {
      return localStorage.getItem(searchKey);
    } else {
      return defaultValue;
    }

  }

  capitalizeParagraph(paragraph) {
    if (typeof paragraph !== 'string') return ''
    return paragraph.charAt(0).toUpperCase() + paragraph.slice(1)
  }

  /**
   * Return readable Date
   * @param date 
   */
  public getPrettyDate(date: string) {

    const months = [
      this.words_traslate.ENERO,
      this.words_traslate.FEBRERO,
      this.words_traslate.MARZO,
      this.words_traslate.ABRIL,
      this.words_traslate.MAYO,
      this.words_traslate.JUNIO,
      this.words_traslate.JULIO,
      this.words_traslate.AGOSTO,
      this.words_traslate.SEPTIEMBRE,
      this.words_traslate.OCTUBRE,
      this.words_traslate.NOVIEMBRE,
      this.words_traslate.DICIEMBRE

    ];


    let readableDate = new Date(date);
    let formatted_date = readableDate.getDate() + this.words_traslate.DE + months[readableDate.getMonth()] + this.words_traslate.DE + readableDate.getFullYear() + this.words_traslate.ALAS + readableDate.getHours() + ":" + readableDate.getMinutes()

    return formatted_date;

  }


  /**
   * Open Toast whith error list
   * @param {string} titleError Title of infobox
   * @param {Array<string>} errorList Array of errors
   */

  public errorListToast(titleError: string, errorList: Array<string>): void {

    let formattedTitleError = this.capitalizeParagraph(titleError)
    let contentPop = '<ul>'

    for (let error of errorList) {

      let formattedError = this.capitalizeParagraph(error)
      contentPop = contentPop + '<li>' + formattedError + '</li>'

    }

    contentPop = contentPop + '</ul>'

    this.toast.error(contentPop, formattedTitleError, {
      enableHtml: true,
      closeButton: true,
      disableTimeOut: true,
      positionClass: 'toast-top-center',
      toastClass: 'mytoast ngx-toastr',
      tapToDismiss: true,

    });

  }

  /**
   * @description show toast error/success 
   * @param {boolean} success
   * @param {string} message 
   * 
   * https://www.npmjs.com/package/ngx-toastr
   * https://ngx-toastr.vercel.app/
   * 
   */

  public runToast(success: boolean, message: string) {

    let formatedMessage = this.capitalizeParagraph(message)

    if (success) {

      this.toast.success(formatedMessage, '', {
        timeOut: 3000,
        closeButton: false,
        positionClass: 'toast-top-center',
        tapToDismiss: true
      });

    } else {

      this.toast.error(formatedMessage, '', {
        disableTimeOut: true,
        closeButton: true,
        positionClass: 'toast-top-center',
        tapToDismiss: true
      });
    }

  }


  /**
   * Show Message error geolocalization (NOT USED)
   * @param message 
   */
  public warningToast(message: string) {

    let formatedMessage = this.capitalizeParagraph(message)

    this.toast.warning(formatedMessage, '', {
      disableTimeOut: true,
      closeButton: true,
      positionClass: 'toast-top-center',
      tapToDismiss: true
    });

  }


  /**
   * 
   * @param {string} error - error message from server
   */

  crashServer(error) {

    // console.log('error from server in toast (fcn crashServer)', error)

    let errormsg = error.message;
    let statusError = error.statusText;

    this.toast.warning(statusError, '', {
      disableTimeOut: true,
      closeButton: true,
      positionClass: 'toast-top-center',
      tapToDismiss: true
    });

  }

  /**
   * Show Message 'spot not found'
   */

  public NotFound = false;

  observerNotFound$ = new BehaviorSubject<any>(this.NotFound)

  msgNotFound(status) {
    this.observerNotFound$.next(status)
  }

  getNotFound() {
    return this.observerNotFound$.asObservable();
  }

  /**
  *  show message 'Only user loged'
  */

  public LoginOrRegister = false;
  public titoloEsegueAzione = '';

  observerLoginOrRegister$ = new BehaviorSubject<any>(this.LoginOrRegister)

  msgLoginOrRegister(status: boolean, titolo: string) {
    this.titoloEsegueAzione = titolo;
    this.observerLoginOrRegister$.next(status)
  }

  getLoginOrRegister() {
    return this.observerLoginOrRegister$.asObservable();
  }


  /** Observable Yolli - used for some process example: close Modal programatically
     * 
     * */
  public statusProcees = false;

  observerstatusProcees$ = new BehaviorSubject<any>(this.statusProcees)

  updateStatusProcees(status) {
    this.observerstatusProcees$.next(status)
  }

  getStatusProcees() {
    return this.observerstatusProcees$.asObservable();
  }

  // Set var position in localstorage (New Spot And Edit Spot)
  public setVarPosition(lat, lng, radius, zoom) {

    this.myLat = Number(lat);
    this.myLng = Number(lng);
    this.radiusMap = Number(radius);
    this.zoomMap = Number(zoom);

    localStorage.setItem('spotyLat', lat.toString());
    localStorage.setItem('spotyLng', lng.toString());
    localStorage.setItem('radiusMap', radius.toString());
    localStorage.setItem('zoomMap', zoom.toString());

  }

  getIfNavigatorIsOk() {

    if (!navigator.geolocation) {

      this.navigatorIsOk = false;
      return { result: false, message: this.words_traslate.INFOGEONOSOPORTADA };
    }

    this.navigatorIsOk = true;
  }



  getSettingLocation() {

    navigator.permissions.query({ name: 'geolocation' }).then((result) => {

      if (result.state == 'granted') {

        this.acceptGeo = true;
      } else if (result.state == 'prompt') {

        this.acceptGeo = true;
      } else if (result.state == 'denied') {

        this.acceptGeo = false;
      }

    });
  }

}
