import { Component, OnInit, Input} from '@angular/core';

@Component({
  selector: 'app-header-yolly',
  templateUrl: './header-yolly.component.html',
  styleUrls: ['./header-yolly.component.css']
})
export class HeaderYollyComponent implements OnInit {

  @Input() title;
  @Input() subtitle;

  constructor() { }

  ngOnInit(): void {
  }

}
