import { Component, OnInit } from '@angular/core';
import { GeneralService } from '../../services/general.service'; //== General service
import { SpotsService } from '../../services/spots.service'; //== General service
import { NgbModal, NgbActiveModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ModSpotManipulationComponent } from './../../components/mod-spot-manipulation/mod-spot-manipulation.component';

@Component({
  selector: 'app-list-filtered',
  templateUrl: './list-filtered.component.html',
  styleUrls: ['./list-filtered.component.css']
})
export class ListFilteredComponent implements OnInit {

  constructor(
    public generalService: GeneralService,
    public spotsService: SpotsService,
    private modalService: NgbModal,
  ) {
    this.generalService.positionPage = 'list_filter'
  }

  ngOnInit(): void {
    
  }

  openFormNuovoSpot() {

    let modal = this.modalService.open(ModSpotManipulationComponent, { size: 'lg', backdrop: 'static' });

    (<ModSpotManipulationComponent>modal.componentInstance).type = 'new';
    modal.result.then((result) => {
   //   console.log('result', result)
      if (result.success) {
        this.spotsService.setListSpots();
        // this.spotsService.setListSpots_prov();
      }
    }, (reason) => {

    //  console.log('reason', reason)




    });
  }
}
