import { Component, OnInit, Input } from '@angular/core';
import { environment } from '../../../environments/environment';
import { GeneralService } from '../../services/general.service'; //== General service
import { Spot } from './../../components/models/spot-model.model';
import { ModSpotManipulationComponent } from './../../components/mod-spot-manipulation/mod-spot-manipulation.component';
import { NgbModal, NgbActiveModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { SpotDetailsComponent } from './../../components/spot-details/spot-details.component';
import { SpotsService } from './../../services/spots.service';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { ChiudeSpotComponent } from './../chiude-spot/chiude-spot.component';


@Component({
  selector: 'app-spotcard',
  templateUrl: './spotcard.component.html',
  styleUrls: ['./spotcard.component.css']
})
export class SpotcardComponent implements OnInit {

  @Input() item;
  @Input() showStatus;

  public spot;
  public editAllowed: boolean = false;
  public isCollapsed: boolean = true;

  constructor(
    public generalService: GeneralService,
    private modalService: NgbModal,
    public spotsService: SpotsService,
    public config: NgbDropdownConfig

  ) {
    config.placement = 'bottom-left';
  }

  ngOnInit(): void {

    // console.log('You are in page: ', this.generalService.positionPage)
    this.spot = this.item;
  }

  onImgError(event) {
    event.target.src = './assets/img/default/null.jpg'
    //Do other stuff with the event.target
  }

  openEditFormSpot() {

    let modal = this.modalService.open(ModSpotManipulationComponent, { size: 'lg', backdrop: 'static' });

    (<ModSpotManipulationComponent>modal.componentInstance).type = 'edit';
    (<ModSpotManipulationComponent>modal.componentInstance).spot = this.item;
    (<ModSpotManipulationComponent>modal.componentInstance).spotId = this.item._id;


    modal.result.then((result) => {

      if (result.success) {
        // this.getDetailsForrefresh();
       // this.spotsService.updateSpot(this.spot._id)

      }

    }, (reason) => { });
  }

  viewDetails() {

    //  console.log('Click in open details of spotCard (' + this.generalService.positionPage + '):', this.item)
    let modal = this.modalService.open(SpotDetailsComponent, {  backdrop: 'static' });
    (<SpotDetailsComponent>modal.componentInstance).spot = this.spot;
    (<SpotDetailsComponent>modal.componentInstance).id = this.spot._id;
    (<SpotDetailsComponent>modal.componentInstance).showStatus = this.showStatus;
    modal.result.then((result) => {

      if (result.success) {
        // this.spotsService.setUserDetails();
        // this.spotsService.setListSpots()
      }

    }, (reason) => {

    });


  }

  trigerLettoComento() {
    this.spotsService.lettoCommento(this.spot);
  }

  deleteSpot() {

    if (confirm(this.generalService.words_traslate.ELIMINARETERNAMENTE)) {

      this.spotsService.sendDeleteSpot(this.spot._id).subscribe(data => {

        this.generalService.loading = false;
        this.generalService.runToast(data['success'], data['message']);

        if (data['success']) {
          this.spotsService.deleteSpotInList(this.spot._id)
          this.spotsService.deleteOfListChiusi(this.spot._id)
        }

      }, error => {

        this.generalService.loading = false;
        this.generalService.crashServer(error);

      });
    }

  }

  getDetailsForrefresh() {

    // console.log('get details')
    this.spotsService.getSingleSpot(this.spot._id).subscribe(data => {
      // console.log(data)

      if (data['success']) {

        //this.item = data['dettagliSpot'];
        //  console.log('dettagliSpot', this.item);
        // this.spot = this.spotsService.createSingleSpot(this.spotsService.username,  data['dettagliSpot']);
        this.spotsService.updateSpot(this.spot._id)

      }

    }, error => {

      //hide overload
      this.generalService.loading = false;

      //show crash message
      this.generalService.crashServer(error)
    });

  }

  deleteComment(id) {

    if (confirm(this.generalService.words_traslate.QUIERESELIMINARMENSAJE)) {

      //  this.generalService.loading = true;
      this.spotsService.deleteComment(this.spot._id, id)
    }

  }

  myClick() {
    this.spotsService.set_idselectedForMarker(this.spot._id)
  }


  NOTviewDetails() {
    this.generalService.msgNotFound(true)
  }

  fixSpotNotFound() {
    this.spotsService.followmeOrNot(this.spot._id, true)
  }


  apriPerChiudere() {

    let modal = this.modalService.open(ChiudeSpotComponent, {});
    (<ChiudeSpotComponent>modal.componentInstance).spot = this.spot;
    modal.result.then((result) => {

    }, (reason) => {
      // console.log('reason',reason)
    });

  }
}
