import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { CommonModule } from '@angular/common';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ModSpotManipulationComponent } from './mod-spot-manipulation.component';
import { ModImageManipulationModule } from '../../components/mod-image-manipulation/mod-image-manipulation.module';
import { SharedTranslateModule } from './../shared-translate/shared-translate.module';


@NgModule({

  imports: [
    CommonModule,
    FormsModule,
    ImageCropperModule,
    ModImageManipulationModule,
    SharedTranslateModule
  ],
  
  declarations: [ModSpotManipulationComponent],
  exports: [
    ModSpotManipulationComponent
  ]
})
 
export class ModSpotManipulationModule { }
