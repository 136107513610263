import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { GeneralService } from '../../services/general.service';
import { SpotsService } from './../../services/spots.service';
import { LoginUser } from './../../components/models/user-data.model';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit, OnDestroy {

  public errorForm: boolean = false;
  public resultRes: boolean;
  public messageRes: string = '';
  public model = new LoginUser('', '');

  constructor(
    public generalService: GeneralService,
    public spotsService: SpotsService,
    private router: Router
  ) {

    this.generalService.positionPage = 'login';
  }

  ngOnInit() {

    let body = document.getElementsByTagName("body")[0];
    body.classList.add("bg-walki");
  }

  ngOnDestroy() {

    let body = document.getElementsByTagName("body")[0];
    body.classList.remove("bg-walki");
  }

  login() {

    if (!this.generalService.isConnect) { return; }

    this.hideAlertError();

    let username = this.model.username.replace(/(^\s*)|(\s*$)/g, "");
    let password = this.model.password.replace(/(^\s*)|(\s*$)/g, "");

    if (username == '' || password == '') {

      this.showAlertError(this.generalService.words_traslate.FALTANCAMPOS);

    } else {

      //hide overload
      this.generalService.loading = true;

      this.spotsService.authenticate(this.model).subscribe(data => {

        //if auth is ok: take the token
        if (data['success']) {

          //set localstorage and local variable token
          localStorage.setItem('token', data['token']);
          localStorage.setItem('certificateUser', 'true');

          this.spotsService.token = data['token'];
          this.spotsService.setUserDetails(true);

          this.generalService.loading = false;
          this.router.navigate(['/mappa', {}]);

        } else {

          //hide overload
          this.generalService.loading = false;

          //show Alert
          this.showAlertError(data['message']);

        }

      }, error => {

        //hide overload
        this.generalService.loading = false;

        //show crash message
        this.generalService.crashServer(error)
      });

    }

  }

  // Show Alert And set Message
  showAlertError(message) {

    this.messageRes = message;
    this.errorForm = true;
  }

  // Hide Alert And empty Message
  hideAlertError() {

    this.errorForm = false;
    this.messageRes = '';
  }

}
