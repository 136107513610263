
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app.routing';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { ToastrService } from 'ngx-toastr'; //== Toast Service
import { ComponentsModule } from './components/components.module';
import { AppComponent } from './app.component';
import { ModalEditUserDataComponent } from './pages/user-profile/modal-edit-user-data/modal-edit-user-data.component';
import { BrowserModule } from '@angular/platform-browser';
import { DonateComponent } from './pages/donate/donate.component';
import { InformazioneComponent } from './pages/informazione/informazione.component';
import { DownloadComponent } from './pages/download/download.component';
import { CookiesComponent } from './pages/cookies/cookies.component';
import { environment } from './../environments/environment';
import { CommonModule } from '@angular/common';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { MapsComponent } from './pages/maps/maps.component';
import { UserProfileComponent } from './pages/user-profile/user-profile.component';
import { LoginComponent } from './pages/login/login.component';
import { RegisterComponent } from './pages/register/register.component';
import { PrivacyComponent } from '././pages/privacy/privacy.component';
import { ModImageManipulationModule } from './components/mod-image-manipulation/mod-image-manipulation.module';
import { ModSpotManipulationModule } from './components/mod-spot-manipulation/mod-spot-manipulation.module';
import { ModalManipulationAvatarComponent } from '././pages/user-profile/modal-manipulation-avatar/modal-manipulation-avatar.component';
import { ListFilteredComponent } from '././pages/list-filtered/list-filtered.component';
import { VerificataEmailComponent } from './pages/verificata-email/verificata-email.component';
import { PasswordRicuperaComponent } from './pages/password-ricupera/password-ricupera.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { GrazieComponent } from './pages/grazie/grazie.component';
import { ContattoComponent } from './pages/contatto/contatto.component';
import {HttpClient} from '@angular/common/http';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { SharedTranslateModule } from './components/shared-translate/shared-translate.module';

/**
 * Config cookie info
 */
// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}


@NgModule({
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    ComponentsModule,
    NgbModule,
    RouterModule,
    AppRoutingModule,
    ToastrModule.forRoot(), // ToastrModule
    BrowserModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    NgbModule,
    ComponentsModule,
    ModImageManipulationModule,
    ModSpotManipulationModule,
    InfiniteScrollModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }, isolate :false
    }),
    SharedTranslateModule
  ],
  declarations: [
    AppComponent,
    ModalEditUserDataComponent,
    DonateComponent,
    InformazioneComponent,
    DownloadComponent,
    CookiesComponent,
    DashboardComponent,
    UserProfileComponent,
    MapsComponent,
    LoginComponent,
    RegisterComponent,
    PrivacyComponent,
    ModalManipulationAvatarComponent,
    ListFilteredComponent,
    VerificataEmailComponent,
    PasswordRicuperaComponent,
    ResetPasswordComponent,
    GrazieComponent,
    ContattoComponent,

  ],
  providers: [
    ToastrService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

  constructor(private toastrService: ToastrService) {

   }

}
