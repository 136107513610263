import { Component, OnInit, Input, AfterViewInit, OnDestroy } from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { ModImageManipulationService } from './mod-image-manipulation.service';
import { SpotsService } from './../../services/spots.service';
import { GeneralService } from './../../services/general.service';
import {NgxImageCompressService} from 'ngx-image-compress';

@Component({
  selector: 'app-mod-image-manipulation',
  templateUrl: './mod-image-manipulation.component.html',
  styleUrls: ['./mod-image-manipulation.component.css']
})

export class ModImageManipulationComponent implements OnInit, AfterViewInit,OnDestroy {

  @Input() imageToEdit: string;
  @Input() maintainAspectRadio: boolean;
  @Input() maxWidth: boolean;
  @Input() rounder: boolean;
  
  imageChangedEvent: any = '';
  croppedImage: any = '';
  showCropper = true;
  fileToReturn: any;
  data: any;
  base64String: any;
  viewPreview=false;

imageInInput='';

  cropper = {
    x1: 100,
    y1: 100,
    x2: 200,
    y2: 200
  }

  constructor(
    public spotsService: SpotsService,
    public generalService: GeneralService,
    public modImageManipulationService: ModImageManipulationService,
    private imageCompress: NgxImageCompressService,
  ) { }

  ngOnInit(): void {

    if (this.imageToEdit.replace(/(^\s*)|(\s*$)/g, "") != 'none') {
      
      // this.base64String=this.getBase64(this.imageToEdit); <==== FOR EDIT EXISTING IMAGE
      this.modImageManipulationService.srcOldPreview= this.imageToEdit;
      this.viewPreview= true;

    }
   
  }

  ngOnDestroy(){
    this.modImageManipulationService.srcOldPreview='';
    this.modImageManipulationService.resultImage='';
    this.modImageManipulationService.imgResultBeforeCompress='';
    this.modImageManipulationService.imgResultAfterCompress='';
  }

  ngAfterViewInit(): void {}


  fileChangeEvent(event: any): void {
    
    this.imageChangedEvent = event;
  //  console.log('fileChangeEvent', event)

  }


  imageCropped(event: ImageCroppedEvent) {

    this.croppedImage = event.base64;
    this.modImageManipulationService.resultImage = this.base64ToFile(event.base64)
    this.modImageManipulationService.srcOldPreview=''
    this.viewPreview=false;
    
    this.fileToReturn = this.base64ToFile(
      event.base64
    )

    return this.fileToReturn;

  }

  imageLoaded(image: HTMLImageElement) {
    // show cropper
   // console.log(image)

    this.showCropper = true;
    
    setTimeout(() => {
      this.cropper = {
        x1: 100,
        y1: 100,
        x2: 300,
        y2: 200
      }
    });



  }
  cropperReady() {
    // cropper ready
    //console.log('cropper ready')

   // console.log('this.croppedImage', this.croppedImage)
    
      this.modImageManipulationService.imgResultBeforeCompress = this.croppedImage;
      // console.warn('Size in bytes was:', this.imageCompress.byteCount(this.croppedImage));
       
       this.imageCompress.compressFile(this.croppedImage, 1, 100, 100).then(
         result => {
       //    console.log(result);
           this.modImageManipulationService.imgResultAfterCompress = result;
           this.croppedImage= result;
           this.modImageManipulationService.resultImage = this.base64ToFile(this.croppedImage)
           //console.warn('Size in bytes is now:', this.imageCompress.byteCount(result));
           this.viewPreview= false;
           this.modImageManipulationService.srcOldPreview=''
         }
       );
   
  }
  loadImageFailed() {
    // show message
 //   console.log('loadImageFailed')
  }

  getImgUrl($event) {
       // debugger
       let data = window.URL.createObjectURL($event.path[0].files[0]);
      // this.cropper.replace(data);
         //console.log($event);
     }

  base64ToFile(data) {

    const arr = data.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);

    const arrType = mime.split('/');
    const ext = arrType[1];

    let fileName = 'immagine.' + ext;
 //   console.log(fileName)
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], fileName, { type: mime });
  }




  getBase64(imgUrl) {

    const self = this;
    var xhr = new XMLHttpRequest();
    xhr.open("get", imgUrl, true);

    // Essential
    xhr.responseType = "blob";

    xhr.onload = function () {

      if (this.status == 200) {

        //Get a blob objects
        var blob = this.response;
        // console.log("blob", blob)

        //  Essential
        let oFileReader = new FileReader();
        oFileReader.onloadend = function (e) {
          let base64 = e.target;
          self.base64String = (<any>base64).result;
       //   console.log("method one", base64)
          //self.img64= base64;
        };
        oFileReader.readAsDataURL(blob);

        //==== In order to display the picture on the page, you can delete ====
        // var img = document.createElement("img");
        // img.onload = function (e) {
        //   window.URL.revokeObjectURL(img.src); //  Clear release
        // };
        // let src = window.URL.createObjectURL(blob);
        // img.src = src
        // document.getElementById("container1").appendChild(img);
        //==== In order to display the picture on the page, you can delete ====

      }
    }
    xhr.send();
  }

  //https://stackblitz.com/edit/ngx-compress-sample?file=app%2Fng-compress-sample.ts
  //https://github.com/dfa1234/ngx-image-compress
  compressFile() {

    this.imageCompress.uploadFile().then(({image, orientation}) => {
    
      this.modImageManipulationService.imgResultBeforeCompress = image;
      //console.warn('Size in bytes was:', this.imageCompress.byteCount(image));
      //console.log('orientation', orientation);
      
      this.imageCompress.compressFile(image, 1, 50, 50).then(
        result => {
       //  console.log(result);
          this.modImageManipulationService.imgResultAfterCompress = result;
          console.warn('Size in bytes is now:', this.imageCompress.byteCount(result));
        }
      );
    });

    
  }

}
