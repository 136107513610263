import { Component, OnInit } from '@angular/core';
import { GeneralService } from '../../services/general.service'; //== General service
@Component({
  selector: 'app-cookies-info',
  templateUrl: './cookies-info.component.html',
  styleUrls: ['./cookies-info.component.css']
})
export class CookiesInfoComponent implements OnInit {

  constructor(
    public generalService: GeneralService
  ) { }

  ngOnInit(): void {
  }

  pressOk() {

    let d = new Date();
    d.setTime(d.getTime() + (15 * 24 * 60 * 60 * 1000));
    let expires = "expires=" + d.toUTCString();
    document.cookie = "eventCookie = Press ok ; " + expires + ";path=/";
    this.generalService.infocookieView = false;
    
  }

}
