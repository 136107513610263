import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { GeneralService } from './services/general.service';
import { SpotsService } from './services/spots.service';
import { Subscription } from 'rxjs';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

import { Router, NavigationEnd } from '@angular/router';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  public title = this.generalService.words_traslate.SITIO;
  public statusConnection = true;
  public infoLoginOrRegister: Subscription;
  public infoNotFound: Subscription;

  constructor(
    public generalService: GeneralService,
    public spotsService: SpotsService,
    private modalService: NgbModal,
    private router: Router,
    public translate: TranslateService
  ) {

 //   translate.setDefaultLang('it');



    /*
        The following properties exist on the navigator object (which can also be known as clientInformation on IE but there's no reason ever to use that name):
    
        language (non-IE, browser install language)
        browserLanguage (IE, browser install language)
        userLanguage (IE, user-level OS-wide language setting)
        systemLanguage (IE, OS installation language)*/

  /*  translate.addLangs(['it', 'es']);
    translate.setDefaultLang('es');
    const browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/it|es/) ? browserLang : 'es');

    if (browserLang == 'es') {

      this.generalService.LatLang = '-34.46197833064051';
      this.generalService.LngLang = '-60.388650881264255';


    }*/
   translate.addLangs(['it', 'es']);
  /*  translate.setDefaultLang('es');
   this.generalService.LatLang = '-34.46197833064051';
   this.generalService.LngLang = '-60.388650881264255';*/
    translate.setDefaultLang('it');
    /*
     translate.addLangs(['it', 'es']);
    translate.setDefaultLang('es');
    this.generalService.LatLang = '-34.46197833064051';
    this.generalService.LngLang = '-60.388650881264255';
    */

    /* translate.addLangs(['es']);
     translate.setDefaultLang('es');
     const browserLang = translate.getBrowserLang();
     translate.use( 'es');*/

  }

  ngOnInit() {

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)

    });
    if (typeof (Worker) !== "undefined") {
      // Yes! Web worker support!
      // Some code.....
      // console.log('Yes! Web worker support!')
    } else {
      // Sorry! No Web Worker support..
    }
    this.generalService.infocookieView = (this.getCookie('eventCookie') == 'Press ok') ? false : true;

    this.generalService.getSettingLocation();
    this.generalService.getIfNavigatorIsOk();

    //this.generalService.viewNabIndeterminate = true;

    /* setInterval(() => {
       this.generalService.timeLocal = true;
       console.log('proxima refresh')
     }, 300000);*/

    this.generalService.skipSpotList == 0

    this.generalService.createOnline$().subscribe(isOnline => {

      this.statusConnection = isOnline;
      this.generalService.isConnect = isOnline;

      if (!isOnline) {

        this.generalService.isUpdate = false;

      } else {

        if (!this.generalService.isUpdate) {

          this.generalService.loading = true;
          this.generalService.loadingConecction = true;
          this.generalService.isUpdate = true;
          location.reload();

        }

      }
    });

    this.listenStatusInfoNotFound();
    this.listenStatusLoginOrRegister();

    this.spotsService.setUserDetails(true);

    this.translate.get('OTROS').subscribe((text: any) => {
      //console.log(text)
      this.generalService.words_traslate = text;
      //console.log(this.generalService.words_traslate.INFOGEONOSOPORTADA)
    });

    /*   this.translate.get('OTROS.INFOGEONOSOPORTADA').subscribe((text: string) => {
      this.generalService.geo_no_soportada_traslate = text;

    });*/

  }

  getCookie(cname) {

    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";

  }

  ngOnDestroy() { }

  listenStatusInfoNotFound() {

    this.generalService.getNotFound().subscribe(
      status => {
        (status) ? this.modalService.open(NgbdModalInfoNotFound, { size: 'sm', backdrop: 'static' }) : 'Modal Hide';

      });
  }

  listenStatusLoginOrRegister() {

    this.generalService.getLoginOrRegister().subscribe(
      status => {
        (status) ? this.modalService.open(NgbdModalInfoLoginOrRegister, { size: 'sm', backdrop: 'static' }) : 'Modal Hide';

      });
  }

}

@Component({
  selector: 'ngbd-modal-content',
  template: `
    <div class="modal-header">
      <!--<img src="./assets/img/brand/red.png" class="navbar-brand-img" alt="..." style=" max-width: 60%;">-->
      <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="pl-lg-4">
       {{ generalService.words_traslate.QUIZAANUNCIOELIMINADO }}
      </div>
    </div>
  `
})
export class NgbdModalInfoNotFound implements OnInit {

  constructor(
    public activeModal: NgbActiveModal,
    public generalService: GeneralService,
    public translate: TranslateService
  ) { }

  ngOnInit(): void { }

  close() {

    this.generalService.msgNotFound(false)
    this.activeModal.close();
  }

}

@Component({
  selector: 'ngbd-modal-content',
  template: `
    <div class="modal-header" style="padding: 5px 1.1rem;border-bottom: 2px solid #e9ecef;">
      <h4 class="modal-title" id="modal-basic-title" style="margin-top: 12px;">{{titolo}}</h4>
      <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
    <p class=" mb-0 text-muted text-sm" style='text-align: center;'>
        <span class='simple-link' (click)='toLogin()'>  {{ generalService.words_traslate.ENTRA }} </span> o <span class='simple-link' (click)='toregister()'>  {{ generalService.words_traslate.REGISTRATE }}</span>  {{ generalService.words_traslate.PARAHACERLAACCION  }}
    </p>
    </div>
  `
})
export class NgbdModalInfoLoginOrRegister implements OnInit {

  public titolo = this.generalService.titoloEsegueAzione;

  constructor(
    public activeModal: NgbActiveModal,
    public generalService: GeneralService,
    private router: Router,
    public ngbModal: NgbModal,
  ) { }

  ngOnInit(): void { }

  close() {
    this.generalService.msgLoginOrRegister(false, '')
    this.activeModal.close();
  }

  toLogin() {
    this.ngbModal.dismissAll()
    this.router.navigate(['/login', {}]);

  }

  toregister() {
    this.ngbModal.dismissAll()
    this.router.navigate(['/register', {}]);

  }

}

