import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { MapsComponent } from './pages/maps/maps.component';
import { UserProfileComponent } from './pages/user-profile/user-profile.component';
import { LoginComponent } from './pages/login/login.component';
import { RegisterComponent } from './pages/register/register.component';
import { PrivacyComponent } from '././pages/privacy/privacy.component';
import { DonateComponent } from '././pages/donate/donate.component';
import { InformazioneComponent } from '././pages/informazione/informazione.component';
import { DownloadComponent } from '././pages/download/download.component';
import { CookiesComponent } from '././pages/cookies/cookies.component';
import { ListFilteredComponent } from '././pages/list-filtered/list-filtered.component';
import { VerificataEmailComponent } from './pages/verificata-email/verificata-email.component';
import { PasswordRicuperaComponent } from './pages/password-ricupera/password-ricupera.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { GrazieComponent } from './pages/grazie/grazie.component';
import { ContattoComponent } from './pages/contatto/contatto.component';

const routes: Routes = [

  { path: '', component: MapsComponent },
  { path: 'elenco', component: DashboardComponent },
  { path: 'elenco_filtri', component: ListFilteredComponent },
  { path: 'user-profile', component: UserProfileComponent },
  { path: 'mappa', component: MapsComponent },
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'ricupera_password', component: PasswordRicuperaComponent },
  { path: 'reset_password/:code', component: ResetPasswordComponent },
  { path: 'contatto', component: ContattoComponent },
  { path: 'mail_verificata', component: VerificataEmailComponent },
  { path: 'privacy', component: PrivacyComponent },
  { path: 'donate', component: DonateComponent },
  { path: 'grazie', component: GrazieComponent },
  { path: 'informazione', component: InformazioneComponent },
  { path: 'scarica', component: DownloadComponent },
  { path: 'cookies', component: CookiesComponent },
  { path: '**', component: MapsComponent }

];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, { useHash: true }),
  ],
  exports: [],
})

export class AppRoutingModule { }
