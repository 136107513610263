import { Component, OnInit, Input, AfterViewInit, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModImageManipulationService } from './../../../components/mod-image-manipulation/mod-image-manipulation.service';
import { SpotsService } from './../../../services/spots.service';
import { GeneralService } from './../../../services/general.service';
import { Subscription } from 'rxjs';  // Importamos el objeto Subscription de rxjs

@Component({
  selector: 'app-modal-manipulation-avatar',
  templateUrl: './modal-manipulation-avatar.component.html',
  styleUrls: ['./modal-manipulation-avatar.component.css']
})

export class ModalManipulationAvatarComponent implements OnInit, OnDestroy {

  @Input() imageToEdit: string;
  @Input() maintainAspectRadio: boolean;

  public statusImage: Subscription;
  public fileName = '';

  constructor(
    public activeModal: NgbActiveModal,
    public spotsService: SpotsService,
    public modImageManipulationService: ModImageManipulationService,
    public generalService: GeneralService
  ) { }

  ngOnInit(): void {
    //  this.viewStatusModal();
    this.modImageManipulationService.resultImage = '';
  }

  private viewStatusImage(): void {

    this.statusImage = this.spotsService.getStatusImage().subscribe(
      status => {

        if (status.isResult) {

          if (!status.success) {

            this.generalService.runToast(status.success, status.message);
            //this.fileName = status.filename;

          } else {
            this.fileName = status.filename;
            this.okSend(status.url, true, status.filename)

          }
          this.statusImage.unsubscribe(); 	// Cancelamos la suscripción cuando se destruya el componente
          this.spotsService.setStatusImage({ isResult: false, success: false, message: '', filename:'' })
        }

      });
  }

  ngOnDestroy(): void { }

  confirmaAccion() {

    if (this.modImageManipulationService.srcOldPreview == '') {

      if (this.modImageManipulationService.resultImage == '') {

        //let errorList= ['Carica una immagine'];
        let errorTitle = this.generalService.words_traslate.CARGAIMAGEN;
        this.generalService.errorListToast(errorTitle, []);


      } else {

        this.viewStatusImage();
        this.generalService.loading = true;
        this.spotsService.uploadImage(this.modImageManipulationService.resultImage, this.modImageManipulationService.resultImage.name, this.modImageManipulationService.resultImage.type);

      }

    } else {

      if (this.modImageManipulationService.resultImage == '') {

        this.okSend(this.modImageManipulationService.srcOldPreview, false, '');

      } else {

        this.viewStatusImage();
        this.generalService.loading = true;
        this.spotsService.uploadImage(this.modImageManipulationService.resultImage, this.modImageManipulationService.resultImage.name, this.modImageManipulationService.resultImage.type);

      }


    }



  }

  okSend(url, isSubscript, filen) {
    
    let bodyC
    if(filen.replace(/(^\s*)|(\s*$)/g, "") == ''){
      bodyC = { avatar: url}
    }else{
      bodyC = { avatar: url , avatarFileName: filen}
    }
  
    this.spotsService.sendCompletFormAvatar(bodyC).subscribe(data => {

      if (data['success']) {

        this.generalService.runToast(data['success'], data['message']);
        this.generalService.loading = false;

        if (isSubscript) {
          this.statusImage.unsubscribe();
          //  this.spotsService.setUserDetails();
          this.spotsService.setStatusImage({ isResult: false, success: false, message: '', filename:'' })
        }

        this.activeModal.close({ success: data['success'], message: data['message'] });

      } else {

        this.generalService.runToast(data['success'], data['message']);
        this.generalService.loading = false;

      }
    }, error => {
      //show crash message


      if (isSubscript) {
        this.statusImage.unsubscribe();
        //  this.spotsService.setUserDetails();
      }
      this.generalService.crashServer(error)

    });
  }

}
