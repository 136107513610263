import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpEvent, HttpErrorResponse, HttpEventType } from "@angular/common/http";
import { environment } from './../../environments/environment';
import { map } from 'rxjs/operators'
import { Spot } from './../components/models/spot-model.model'
import { BehaviorSubject, Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { GeneralService } from './general.service';
import { Router } from '@angular/router';
import { UserData, UserDataNav, StatusImage } from './../components/models/user-data.model';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';

import { of } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class SpotsService {

  isLat = this.generalService.searchKeyInLocalstorage('spotyLat', this.generalService.myLat.toString());
  isLng = this.generalService.searchKeyInLocalstorage('spotyLng', this.generalService.myLng.toString());

  userDataNav: UserDataNav = {
    username: this.generalService.searchKeyInLocalstorage('username', ''),
    urlAvatar: this.generalService.searchKeyInLocalstorage('urlAvatar', this.generalService.defAvatarUrl),
  };

  public username: string = this.generalService.searchKeyInLocalstorage('username', '');
  public urlAvatar: string = this.generalService.searchKeyInLocalstorage('urlAvatar', this.generalService.defAvatarUrl);
  public emailUser: string = '';
  public dataUser = new UserData('', false, '', this.generalService.defAvatarUrl, true, '', false, '', [], []);
  public token: string = '';
  public signOfMessagesNotReader = false;

  public server_spotMap = [];
  public spotList = [];
  public mySpots = [];
  public followingSpots = [];
  public spotMap = [];
  public spotListInBound = [];
  public mySpotsClosed = [];

  numfollowing = 0;
  numMyPost = 0;
  numMyPostClosed = 0;

  //Filter
  public filterSelected = 'tutti';
  public listSelected = [];
  public composeListSelected = [];

  // == Observables spot selected on maplist
  private _idselectedForMarker: BehaviorSubject<string> = new BehaviorSubject(
    ''
  );

  // Método público para quien quiera escuchar
  public get_idselectedForMarker(): Observable<string> {
    return this._idselectedForMarker.asObservable();
  }

  // Método público para quien quiera emitir un mensaje
  public set_idselectedForMarker(_id): void {
    this._idselectedForMarker.next(_id);
  }

  options = {
    // headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8')
    headers: new HttpHeaders().set('Content-Type', 'application/multipart/form-data')
  };

  options2 = {
    headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8')
    //headers: new HttpHeaders().set('Content-Type', 'application/multipart/form-data')
  };

  // Observable Myfollowing

  observerdataNav$ = new BehaviorSubject<UserDataNav>(
    this.userDataNav
  )

  getDataForNav() {
    return this.observerdataNav$.asObservable();
  }

  setDataNav(newUserdata: UserDataNav) {
    this.observerdataNav$.next(newUserdata)
  }

  private statusUploadImage: BehaviorSubject<StatusImage> = new BehaviorSubject({
    isResult: false, success: true, message: '', url: '', filename: '',
  });

  // Método público para quien quiera escuchar
  public getStatusImage(): Observable<StatusImage> {
    return this.statusUploadImage.asObservable();
  }

  // Método público para quien quiera emitir un mensaje
  public setStatusImage(status): void {
    this.statusUploadImage.next(status);
  }

  //== spot in view

  private spotDetailsInView: BehaviorSubject<Spot> = new BehaviorSubject(
    new Spot('', false, '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', false, [], 0, false, [], 0, false)
  );

  // Método público para quien quiera escuchar
  public getSpotDetailsInView(): Observable<Spot> {
    return this.spotDetailsInView.asObservable();
  }

  // Método público para quien quiera emitir un mensaje
  public setSpotDetailsInView(status): void {
    this.spotDetailsInView.next(status);
  }

  public setDefaultSpotDetailsInView() {
    this.setSpotDetailsInView(new Spot('', false, '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', false, [], 0, false, [], 0, false))
  }

  // SpotList Observable
  observerSpotList$ = new BehaviorSubject<any>(this.spotList)

  updateSpot(idSpot) {

    if (!this.generalService.isConnect) { return; }

    this.getSingleSpot(idSpot).subscribe(data => {

      if (data['success']) {

        let newT = this.createSingleSpot(this.username, data['dettagliSpot']);
        this.refrehDetailsPostInLists(idSpot, data['dettagliSpot'], newT);
        this.refreshDetails(idSpot, newT);

      } else {

        this.generalService.msgNotFound(true);
      }

    }, error => { this.generalService.crashServer(error) });


  }

  emitSpotList() {
    localStorage.setItem('spotList_C', JSON.stringify(this.spotList));
    this.observerSpotList$.next(this.spotList)
    //  console.log(this.spotList)
    // console.log('emit')
  }


  // Observable Myspot Chiusi
  observerMySpotListClosed$ = new BehaviorSubject<any>(this.mySpotsClosed)

  // Observable Myspot
  observerMySpotList$ = new BehaviorSubject<any>(this.mySpots)

  updateMySpot(idSpot) {

    if (!this.generalService.isConnect) { return; }

    this.getSingleSpot(idSpot).subscribe(data => {

      if (data['success']) {

        let newT = this.createSingleSpot(this.username, data['dettagliSpot']);
        this.refrehDetailsPostInLists(idSpot, data['dettagliSpot'], newT);
        this.refreshDetails(idSpot, newT);


      } else {
        // this.generalService.msgNotFound();
      }

    }, error => { this.generalService.crashServer(error) });
  }

  emitMySpotList() {

    this.signOfMessagesNotReader = false;
    for (let i = 0; i < this.mySpots.length; i++) {

      if (this.mySpots[i].newComments == true) {
        this.signOfMessagesNotReader = true;

      }

    }

    //  console.log('mySpots', this.mySpots)
    //  console.log('this.signOfMessagesNotReader', this.signOfMessagesNotReader)

    this.numMyPost = this.mySpots.length;
    this.mySpots.sort((a, b) => b.insertDate < a.insertDate ? - 1 : Number(b.insertDate > a.insertDate))
    localStorage.setItem('mySpots_C', JSON.stringify(this.mySpots));
    this.observerMySpotList$.next(this.mySpots)

  }

  emitMySpotListClosed() {

    this.numMyPostClosed = this.mySpotsClosed.length;
    this.mySpotsClosed.sort((a, b) => b.insertDate < a.insertDate ? - 1 : Number(b.insertDate > a.insertDate))
    localStorage.setItem('mySpotsClosed_C', JSON.stringify(this.mySpotsClosed));
    this.observerMySpotListClosed$.next(this.mySpotsClosed)
  }


  // Observable Myfollowing
  observerMyFollowingList$ = new BehaviorSubject<any>(this.followingSpots)

  updateMyFollowing(idSpot) {

    if (!this.generalService.isConnect) { return; }

    this.getSingleSpot(idSpot).subscribe(data => {

      if (data['success']) {

        let newT = this.createSingleSpot(this.username, data['dettagliSpot']);
        this.refrehDetailsPostInLists(idSpot, data['dettagliSpot'], newT);
        this.refreshDetails(idSpot, newT);


      } else {
        // this.generalService.msgNotFound();
      }

    }, error => { this.generalService.crashServer(error) });

  }

  emitMyFollowingList() {

    this.numfollowing = this.followingSpots.length;
    this.followingSpots.sort((a, b) => b.insertDate < a.insertDate ? - 1 : Number(b.insertDate > a.insertDate))
    localStorage.setItem('myFollowing_C', JSON.stringify(this.followingSpots));
    this.observerMyFollowingList$.next(this.followingSpots)
  }

  private cache = new Map<string, any>();

  constructor(
    private http: HttpClient,
    public toast: ToastrService,
    public generalService: GeneralService,
    private router: Router,
  ) { }


  /*
   router.events
      .subscribe((event: NavigationStart) => {
        if (event.navigationTrigger === 'popstate') {
          // Perform actions
        }
      });
      */

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (!this.generalService.isConnect) {
      //  console.log('not connect')
    }

    if (request.method !== 'GET') {
      return next.handle(request);
    }

    const cachedResponse = this.cache.get(request.url);
    if (cachedResponse) {
      //    console.log('cachedResponse')
      return of(cachedResponse);
    }

    return next.handle(request).pipe(
      tap(event => {
        if (event instanceof HttpResponse) {
          this.cache.set(request.url, event);
        }
      })
    );

  }

  /** authenticate
   * 
   * @param user 
   * @returns 
   */
  authenticate(user: object) {
    if (!this.generalService.isConnect) { return; }
    return this.http.post(environment.server + 'api/authenticate', user);
  }

  /** userDetails
   * 
   * @returns 
   */
  userDetails() {

    if (!this.generalService.isConnect) { return; }

    let auth = localStorage.getItem('token');
    const headers = { 'Authorization': auth }

    return this.http.get<any>(environment.server + 'api/listUserDetails', { headers })
  }

  /** registerProccess
   * 
   * @param newUser 
   * @returns 
   */
  registerProccess(newUser: object) {

    if (!this.generalService.isConnect) { return; }
    return this.http.post(environment.server + 'api/signup', newUser);
  }

  /** setUserDetails
   * 
   * @param includeLists 
   * @returns 
   */
  public setUserDetails(includeLists: boolean) {

    if (!this.generalService.isConnect) { return; }

    if (this.couldBeLogged()) {

      let auth = localStorage.getItem('token');
      const headers = { 'Authorization': auth }

      return this.http.get<any>(environment.server + 'api/listUserDetails', { headers }).subscribe(data => {

        this.createprofileUser(data, includeLists);

      }, error => {

        this.generalService.crashServer(error);
        this.generalService.loadingConecction = false;
        this.generalService.loading = false;
        this.generalService.viewNabIndeterminate = false;

      });

    } else {

      this.createprofileUser({}, false);
    }

  }

  /** createprofileUser
   * 
   * @param data 
   * @param includeLists 
   * @returns 
   */
  private createprofileUser(data: object, includeLists: boolean) {

    let isLat = this.generalService.searchKeyInLocalstorage('spotyLat', this.generalService.myLat.toString());
    let isLng = this.generalService.searchKeyInLocalstorage('spotyLng', this.generalService.myLng.toString());
    let rad = this.generalService.searchKeyInLocalstorage('radiusMap', '3');
    let zoom = this.generalService.searchKeyInLocalstorage('zoomMap', '12');

    this.generalService.myLat = Number(isLat);
    this.generalService.myLng = Number(isLng);

    this.generalService.setVarPosition(Number(isLat), Number(isLng), Number(rad), Number(zoom))

    if ("userDetails" in data) {

      // console.log(data['userDetails'])
      localStorage.setItem('certificateUser', 'true');
      localStorage.setItem('username', data['userDetails']['username']);

      let _id = data['userDetails']['_id'];
      let username = data['userDetails']['username'];
      let defaultAvatarSet = data['userDetails']['defaultAvatarSet'];
      let urlAvatar = defaultAvatarSet ? this.generalService.defAvatarUrl : data['userDetails']['avatar'];
      let userEmail = (data['userDetails']['email'] == 'Configura e-mail') ? '' : data['userDetails']['email'];
      let mailValidated = data['userDetails']['mailValidated'];
      let signDate = data['userDetails']['signDate'];
      let mySpots = data['userDetails']['mySpots'];
      let followingSpots = data['userDetails']['spotSeguiti'];

      this.numfollowing = data['userDetails']['spotSeguiti'].length;
      this.numMyPost = data['userDetails']['mySpots'].length;

      this.dataUser = new UserData(_id, true, username, urlAvatar, defaultAvatarSet, userEmail, mailValidated, signDate, mySpots, followingSpots)

      this.username = data['userDetails']['username'];
      this.urlAvatar = data['userDetails']['avatar'];
      this.emailUser = data['userDetails']['email'];

      localStorage.setItem('urlAvatar', defaultAvatarSet ? this.generalService.defAvatarUrl : data['userDetails']['avatar']);

      if (includeLists) {

        this.generalService.viewNabIndeterminate = false;

        this.composedSpotsListMySpot(data['userDetails']['mySpots'])
        //    this.composedSpotsListMySpotClosed(data['userDetails']['MySpotsClosed'])


        this.composedSpotsListFollowingSpots(data['userDetails']['spotSeguiti'])

        this.numfollowing = data['userDetails']['spotSeguiti'].length;
        this.numMyPost = data['userDetails']['mySpots'].length;

      }

      this.setDataNav({
        username: this.username,
        urlAvatar: this.urlAvatar
      });

      this.generalService.loading = false;
      this.generalService.loadingConecction = false;

      localStorage.setItem('utente', JSON.stringify(this.dataUser));

      return;

    }

    localStorage.setItem('certificateUser', 'false');

    localStorage.removeItem('token');
    localStorage.removeItem('username');
    localStorage.removeItem('urlAvatar');

    this.username = 'Ospite';
    this.urlAvatar = this.generalService.defAvatarUrl;
    this.emailUser = '';

    this.dataUser = new UserData('', false, '', this.generalService.defAvatarUrl, true, '', false, '', [], []);

    this.numfollowing = 0;
    this.numMyPost = 0;

    this.followingSpots = [];
    this.mySpots = [];

    this.setDataNav({
      username: this.username,
      urlAvatar: this.urlAvatar
    });

    this.generalService.loading = false;
    this.generalService.loadingConecction = false;

  }

  /** destroyprofileUser
   * 
   */
  public destroyprofileUser() {

    localStorage.setItem('certificateUser', 'false');
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    localStorage.removeItem('urlAvatar');
    localStorage.removeItem('utente');
    localStorage.removeItem('mySpots_C');
    localStorage.removeItem('myFollowing_C');

    this.dataUser = new UserData('', false, '', this.generalService.defAvatarUrl, true, '', false, '', [], []);
    this.setUserDetails(false);

  }

  /** Utility Spots (single and list) FORMAT
   * 
   */
  createSingleSpot(currentUser, spot) {

    let singleSpot: Spot;

    if (spot) {

      let tagC = this.generalService.getClassTag(spot.tag);
      let tagS = this.generalService.getClassStatus(spot.status);

      let tagnewCommentReceived = false;
      let risoluzione = '';

      if ("newCommentReceived" in spot) {
        if (currentUser == spot.username) {
          tagnewCommentReceived = spot.newCommentReceived;
        }
      }

      if ("risoluzione" in spot) {
        risoluzione = spot.risoluzione;
      }

      let dir = '';

      if (spot['direction']) {
        dir = (spot['direction'].comune && spot['direction'].provincia) ? spot['direction'].comune + ' (' + spot['direction'].provincia + ')' : 'non specificato';

      }

      singleSpot = {

        _id: spot._id ? spot._id : '',
        currentUserIsAuthor: currentUser == spot.username ? true : false,
        spotImage: spot.spotImage ? spot.spotImage : '',
        titolo: spot.titolo ? spot.titolo : '',
        testo: spot.testo ? spot.testo : '',
        lat: spot['location'].coordinates ? spot['location'].coordinates[1] : '',
        long: spot['location'].coordinates ? spot['location'].coordinates[0] : '',
        direction: dir,
        username: spot.username ? spot.username : '',
        status: spot.status ? spot.status : '',
        status_ui: spot.status ? this.generalService.traslate_stato(spot.status) : '',
        risoluzione: risoluzione,
        tag: spot.tag ? spot.tag : '',
        tag_ui: spot.tag ? this.generalService.traslate_tag(spot.tag) : '',
        insertDate: spot.insertDate ? this.generalService.words_traslate.PUBLICADOPOR + spot.username + ' - ' + this.generalService.getPrettyDate(spot.insertDate) : '',
        classTag: tagC,
        classStatus: tagS,
        editAllowed: currentUser == spot.username ? true : false,
        commenti: spot.comments,
        numComments: spot.comments.length,
        newComments: tagnewCommentReceived,
        followers: spot.followers,
        numFollowers: spot.followers.length,
        currentUserisFollower: spot.followers.includes(currentUser),

      }
      //  console.log('objeto spot del cliente', singleSpot);
    } else {

      singleSpot = new Spot('', false, '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', false, [], 0, false, [], 0, false);

    }

    return singleSpot;

  }

  /** Single Spot
   */

  // Emit obser
  getDetailsSingleSpotInView(id) {
    if (!this.generalService.isConnect) { return; }
    this.http.get<any>(environment.server + 'api/dettagliSpot/' + id).subscribe(data => {

      if (data['success']) {
        this.setSpotDetailsInView(this.createSingleSpot(this.username, data['dettagliSpot']));
      } else {
        this.generalService.msgNotFound(true);
      }

    }, error => {

      this.generalService.crashServer(error)
    });
  }

  getSingleSpot(id) {
    if (!this.generalService.isConnect) { return; }
    return this.http.get<any>(environment.server + 'api/dettagliSpot/' + id)
  }

  /** Set List Spot And Spot Maps
   * 
   */

  getSpotsMapa(obj) {
    if (!this.generalService.isConnect) { return; }
    // return this.http.post<any>(environment.server + 'api/listaSpotsMappa', obj)
    return this.http.get<any>(environment.server + 'api/allSpotsForMapView', obj)

  }

  setListSpots() {

    /* if (this.generalService.timeLocal) {
       this.refreshInLocal();
       return;
     }*/

    if (!this.generalService.isConnect) { return; }


    let lat = (localStorage.getItem('spotyLat')) ? localStorage.getItem('spotyLat') : this.generalService.myLat;
    let long = (localStorage.getItem('spotyLng')) ? localStorage.getItem('spotyLng') : this.generalService.myLng;
    let radiusMap = (localStorage.getItem('radiusMap')) ? localStorage.getItem('radiusMap') : this.generalService.radiusMap;

    let loc = {

      'userLat': lat,
      'userLong': long,
      'radius': radiusMap,
      'limit': this.generalService.limitSpotList,
      'skip': this.generalService.skipSpotList
    }
    this.generalService.viewNabIndeterminate = true;
    const req = new HttpRequest('POST', environment.server + 'api/listaSpotsMappa', loc, {
      reportProgress: true
    });

    this.http.request(req).subscribe((event: HttpEvent<any>) => {

      switch (event.type) {
        case HttpEventType.Sent:
          //   console.log('Request sent!');
          break;
        case HttpEventType.ResponseHeader:
          //   console.log('Response header received!');
          break;
        case HttpEventType.DownloadProgress:
          const kbLoaded = Math.round(event.loaded / 1024);
          //  console.log(`Download in progress! ${kbLoaded}Kb loaded`);
          break;
        case HttpEventType.Response:
          //    console.log('😺 Done!', event.body);

          let data = event.body;

          if (data['success']) {

            if (this.generalService.skipSpotList == 0) {

              this.spotList = [];
              this.emitSpotList();

            }
            this.composedSpotsList(data['spots']);
            this.generalService.viewNabIndeterminate = false;
          } else {

            if (this.generalService.skipSpotList == 0) {

              this.spotList = [];
              this.emitSpotList()

            }
            this.generalService.viewNabIndeterminate = false;
          }
      }
    }, error => { });
  }

  setListSpotsCurrentUser() {
    if (!this.generalService.isConnect) { return; }
    //mySpots
    let auth = localStorage.getItem('token');
    const headers = { 'Authorization': auth }

    return this.http.get<any>(environment.server + 'api/listUserDetails', { headers }).subscribe(data => {

      //   console.log('my Spots fron Server (setListSpotsCurrentUser) ==> (composedSpotsListMySpot)', data['userDetails'].mySpots)
      this.composedSpotsListMySpot(data['userDetails'].mySpots)


    }, error => {
      //show crash message
      this.generalService.crashServer(error)

    });

  }

  setListFollowingCurrentUser() {

    if (!this.generalService.isConnect) { return; }
    let auth = localStorage.getItem('token');
    const headers = { 'Authorization': auth }

    return this.http.get<any>(environment.server + 'api/listUserDetails', { headers }).subscribe(data => {


      this.composedSpotsListFollowingSpots(data['userDetails'].spotSeguiti)

    }, error => {

      this.generalService.crashServer(error)

    });

  }

  composedSpotsList(spots) {

    //if(arrayDestination==)
    if (this.generalService.skipSpotList == 0) {
      //  console.log('reseteamos skip HACER')
      this.spotList = [];
      this.emitSpotList();
    } else { }

    for (let val of spots) {

      this.spotList.push(this.createSingleSpot(this.username, val))
      this.emitSpotList()

    }
  }

  composedSpotsListMySpot(spots) {

    this.mySpots = [];

    this.emitMySpotList()
    //console.log(spots)

    for (let val of spots) {

      if (val.status == 'aperto') {
        this.mySpots.push(this.createSingleSpot(this.username, val))
      } else {
        this.mySpotsClosed.push(this.createSingleSpot(this.username, val))
      }

      this.emitMySpotList()
      this.emitMySpotListClosed()
      this.generalService.viewNabIndeterminate = false;

    }

  }

  composedSpotsListFollowingSpots(spots) {


    this.followingSpots = [];
    this.emitMyFollowingList()

    for (let val of spots) {
      this.followingSpots.push(this.createSingleSpot(this.username, val))
      this.emitMyFollowingList()
      this.generalService.viewNabIndeterminate = false;

    }

  }

  composedSpotsFilterList() {

    this.composeListSelected = [];

    for (let val of this.listSelected) {
      this.composeListSelected.push(this.createSingleSpot(this.username, val))
    }

  }


  /** Insert, Edit And Delete Spot
   * 
   */

  inviaNuovoSpot(ob) {
    if (!this.generalService.isConnect) { return; }
    let auth = localStorage.getItem('token');
    const headers = { 'Authorization': auth }

    return this.http.post<any>(environment.server + 'api/insertNewSpot', ob, { headers });

  }

  inviaeditSpot(id, nuevoSpot) {
    if (!this.generalService.isConnect) { return; }
    let auth = localStorage.getItem('token');
    const headers = { 'Authorization': auth, }

    return this.http.put<any>(environment.server + 'api/modificaSpot/' + id, nuevoSpot, { headers });

  }

  sendDeleteSpot(id) {
    if (!this.generalService.isConnect) { return; }
    let auth = localStorage.getItem('token');
    const headers = { 'Authorization': auth }
    return this.http.delete<any>(environment.server + 'api/cancellaSpot/' + id, { headers })

  }

  chiudeSpot(idSpot, risoluzione) {

    let auth = localStorage.getItem('token');
    const headers = { 'Authorization': auth }

    return this.http.put<any>(environment.server + 'api/chiudiSpot/' + idSpot, risoluzione, { headers })


  }


  /** Following Or Not
   * 
   */

  followmeOrNot(id, currentValue) {

    if (!this.generalService.isConnect) { return; }

    this.generalService.loading = true;
    let auth = localStorage.getItem('token');
    const headers = { 'Authorization': auth }

    // let action = currentValue ? 'api/unFollowSpot/' : 'api/followSpot/';

    this.http.put<any>(environment.server + 'api/followSpot/' + id, {}, { headers }).subscribe(data => {

      //  console.log('response of unFollowSpot OR followSpot: ', data);

      if (data['success']) {

        this.generalService.loading = false;
        this.setListFollowingCurrentUser();

        let spotAggiornadoS = data['spot'];
        let spotAggiornadoC = this.createSingleSpot(this.username, data['spot']);
        this.refrehDetailsPostInLists(id, spotAggiornadoS, spotAggiornadoC);
        this.refreshDetails(id, spotAggiornadoC);

      } else {

        this.generalService.loading = false;
      }

      this.generalService.runToast(data['success'], data['message']);

    }, error => {
      //show crash message
      this.generalService.loading = false;
      this.generalService.crashServer(error)
      return false;
    });
  }


  /** Comment Send And Delete
  * 
  */

  deleteComment(idSpot, idComment) {

    if (!this.generalService.isConnect) { return; }
    let auth = localStorage.getItem('token');
    const headers = { 'Authorization': auth }

    return this.http.delete<any>(environment.server + 'api/cancellaCommento/' + idSpot + '/' + idComment, { headers }).subscribe(data => {

      this.generalService.loading = false;
      this.generalService.runToast(data['success'], data['message']);

      if (data['success']) {

        //console.log('response of cancellaCommento: ', data);
        let spotAggiornadoS = data['spot'];
        let spotAggiornadoC = this.createSingleSpot(this.username, data['spot']);
        this.refrehDetailsPostInLists(idSpot, spotAggiornadoS, spotAggiornadoC);
        this.refreshDetails(idSpot, spotAggiornadoC)
      }

    }, error => {

      this.generalService.loading = false;
      this.generalService.crashServer(error);

    });

  }

  sendComment(id, comment) {

    if (!this.generalService.isConnect) { return; }
    let msgError = [];
    if (comment.replace(/(^\s*)|(\s*$)/g, "") == '') {

      msgError = []
      this.generalService.errorListToast('Scrivi un commento', msgError);

    } else {

      this.generalService.loading = true;
      let auth = localStorage.getItem('token');
      const headers = { 'Authorization': auth }

      let body = { 'testoCommento': comment }
      this.http.post<any>(environment.server + 'api/inserisciCommentoAggiornaSpot/' + id, body, { headers }).subscribe(data => {

        this.generalService.loading = false;
        this.generalService.runToast(data['success'], data['message']);

        if (data['success']) {

          let spotAggiornadoS = data['spot'];
          let spotAggiornadoC = this.createSingleSpot(this.username, data['spot']);
          this.refrehDetailsPostInLists(id, spotAggiornadoS, spotAggiornadoC);
          this.refreshDetails(id, spotAggiornadoC)
          this.generalService.updateStatusProcees(false)

        }

      }, error => {

        this.generalService.loading = false;
        this.generalService.crashServer(error);

      });

    }
  }

  lettoCommento(spot) {

    if (!this.generalService.isConnect) { return; }
    if (!spot.newComments) { return; }
    if (!spot.editAllowed) { return; }

    let idSpot = spot._id;

    let auth = localStorage.getItem('token');
    const headers = { 'Authorization': auth }

    return this.http.put<any>(environment.server + 'api/lettoCommento/' + idSpot, {}, { headers }).subscribe(data => {

      this.generalService.loading = false;
      // this.generalService.runToast(data['success'], data['message']);

      if (data['success']) {

        let spotAggiornadoS = data['spot'];
        let spotAggiornadoC = this.createSingleSpot(this.username, data['spot']);
        this.refrehDetailsPostInLists(idSpot, spotAggiornadoS, spotAggiornadoC);
        this.refreshDetails(idSpot, spotAggiornadoC)

      }

      this.emitMySpotList();

    }, error => {

      this.generalService.loading = false;
      this.generalService.crashServer(error);

    });

  }


  /** User Profile Manipulation (change Avatar - edit Information etc)
  * 
  */

  updateUserProfile(user: object) {

    if (!this.generalService.isConnect) { return; }
    let auth = localStorage.getItem('token');
    const headers = { 'Authorization': auth }
    return this.http.put<any>(environment.server + 'api/updateUserProfile', user, { headers })

  }

  updateUserPass(pass: object) {

    if (!this.generalService.isConnect) { return; }
    let auth = localStorage.getItem('token');
    const headers = { 'Authorization': auth }
    return this.http.put<any>(environment.server + 'api/changeUserPassword', pass, { headers })

  }

  sendCompletFormAvatar(bodyC) {

    if (!this.generalService.isConnect) { return; }
    let auth = localStorage.getItem('token');
    const headers = { 'Authorization': auth, }

    return this.http.put<any>(environment.server + 'api/changeUserAvatar', bodyC, { headers })

  }


  /** Uploader Image
   * 
   * 
   * @param file 
   * @param filename 
   * @param filetype 
   */

  uploadImage(file, filename, filetype) {

    if (!this.generalService.isConnect) { return; }

    this.getKeyForImage(filename, filetype).subscribe(data => {

      // console.log('data of getKeyForImage', data)

      if (data['signedRequest']) {

        let signedRequest = data['signedRequest'];
        let url = data['url'];
        let filename = data['fileName'];

        // this.sendimageToAmazon(file, signedRequest, url);

        const xhr = new XMLHttpRequest();
        xhr.open('PUT', signedRequest);
        xhr.onreadystatechange = () => {
          if (xhr.readyState === 4) {

            if (xhr.status === 200) {

              this.setStatusImage({ isResult: true, success: true, message: '', url: url, filename: filename });

            } else {
              this.setStatusImage({ isResult: true, success: false, message: this.generalService.words_traslate.IMPOSIBLECARGARFILE, url: url, filename: filename });
              this.generalService.runToast(false, this.generalService.words_traslate.NOPUDIMOSCAMBIARIMAGENDEPERFIL);
            }
          }
        };
        xhr.send(file);

      } else {
        //  console.log('error en getKeyForImage')
        this.generalService.runToast(false, this.generalService.words_traslate.NOPUDIMOSCAMBIARIMAGENDEPERFIL);

      }

    }, error => {
      //show crash message
      this.generalService.crashServer(error)

    });

  }

  getKeyForImage(filename, filetype) {

    if (!this.generalService.isConnect) { return; }
    let auth = localStorage.getItem('token');
    const headers = { 'Authorization': auth, }
    let body = { filename: filename, filetipe: filetype }

    return this.http.post<any>(environment.server + 'api/sign-s3', body, { headers })

  };


  /**
   *  if there is a token  in localstorage the user could be logged
   * @returns {boolean}
   */
  couldBeLogged(): boolean {

    let loged = true;

    if (localStorage.getItem('token')) { } else { loged = false; }

    if (loged) { return true; }

    localStorage.setItem('certificateUser', 'false');
    return false;

  }


  refreshDetails(idSpot, newT) {

    if (this.spotDetailsInView.value._id == idSpot) {
      this.setSpotDetailsInView(newT);
    }

  }

  /**
   * 
   * @param idSpot 
   * @param spotS 
   * @param newT 
   */
  refrehDetailsPostInLists(idSpot, spotS, newT) {

    this.spotList.findIndex((post, index) => {

      if (post._id == idSpot) {
        this.spotList[index] = newT;
      }

    });

    this.spotListInBound.findIndex((post, index) => {

      if (post._id == idSpot) {
        this.spotListInBound[index] = newT;
      }

    });

    localStorage.setItem('spotListInBound_S', JSON.stringify(this.spotListInBound));

    this.mySpots.findIndex((post, index) => {

      if (post._id == idSpot) {

        this.mySpots[index] = newT;

      }

    });

    this.server_spotMap.findIndex((post, index) => {

      if (post._id == idSpot) {
        this.server_spotMap[index] = spotS;
        localStorage.setItem('spotsMapa_S', JSON.stringify(this.server_spotMap));
      }

    });

    localStorage.setItem('spotsMapa_S', JSON.stringify(this.server_spotMap));

    this.followingSpots.findIndex((post, index) => {

      if (post._id == idSpot) {

        this.followingSpots[index] = newT;

      }

    });

    this.observerSpotList$.next(this.spotList)
    this.observerMyFollowingList$.next(this.followingSpots)
    this.emitSpotList()

    for (let i = 0; i < this.composeListSelected.length; i++) {
      if (this.composeListSelected[i]._id == idSpot) {
        this.composeListSelected[i] = newT;

      }
    }

  }

  addingSpotInLists(spot) {

    this.mySpots.push(this.createSingleSpot(this.username, spot))
    this.spotList.push(this.createSingleSpot(this.username, spot))
    this.server_spotMap.push(spot)

    this.emitSpotList()
    this.emitMySpotList()

  }

  newChiusiInList(spot) {
    this.mySpotsClosed.push(this.createSingleSpot(this.username, spot))
    this.emitMySpotListClosed()
  }

  deleteOfListChiusi(idSpot) {

    for (let i = 0; i < this.mySpotsClosed.length; i++) {

      if (this.mySpotsClosed[i]._id == idSpot) {
        this.mySpotsClosed.splice(i, 1);
      }

    }
    this.emitMySpotListClosed()
  }

  deleteSpotInList(idSpot) {

    for (let i = 0; i < this.spotList.length; i++) {

      if (this.spotList[i]._id == idSpot) {
        this.spotList.splice(i, 1);
      }

    }

    for (let i = 0; i < this.server_spotMap.length; i++) {

      if (this.server_spotMap[i]._id == idSpot) {
        this.server_spotMap.splice(i, 1);
      }

    }

    for (let i = 0; i < this.composeListSelected.length; i++) {

      if (this.composeListSelected[i]._id == idSpot) {
        this.composeListSelected.splice(i, 1);
      }

    }

    for (let i = 0; i < this.followingSpots.length; i++) {

      if (this.followingSpots[i]._id == idSpot) {
        this.followingSpots.splice(i, 1);
      }

    }

    for (let i = 0; i < this.spotListInBound.length; i++) {

      if (this.spotListInBound[i]._id == idSpot) {
        this.spotListInBound.splice(i, 1);
      }

    }

    for (let i = 0; i < this.mySpots.length; i++) {

      if (this.mySpots[i]._id == idSpot) {
        this.mySpots.splice(i, 1);
      }

    }

    localStorage.setItem('spotsMapa_S', JSON.stringify(this.server_spotMap));
    localStorage.setItem('spotListInBound_S', JSON.stringify(this.spotListInBound));

    this.observerSpotList$.next(this.spotList)
    this.observerMyFollowingList$.next(this.followingSpots)

    this.emitSpotList();
    this.emitMySpotList();
    // this.emitMySpotListClosed();

  }

  logout() {

    this.destroyprofileUser();

    this.generalService.runToast(true, 'Arrivederci!!!');

    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {

      if (currentUrl == '/user-profile') {
        this.router.navigate(['/mappa']);
        location.reload();
      } else {
        location.reload();
      }

    });

  }


  richiestaNewPass(user) {
    if (!this.generalService.isConnect) { return; }
    return this.http.post(environment.server + 'api/sendResetCode', user);
  }

  resetPassword(user) {
    if (!this.generalService.isConnect) { return; }
    return this.http.post(environment.server + 'api/resetPassword', user);

  }

  createArrayBound(idBound) {

    //for each get spot for array spotlist
    for (let i = 0; i < idBound.length; i++) {

      let id = idBound[i];
      this.spotList.findIndex((post, index) => {

        if (post._id == id) {
          this.spotListInBound.push(this.spotList[index]);
        }

      });
    }
    localStorage.setItem('spotListInBound_S', JSON.stringify(this.spotListInBound));

  }

  eliminaCuenta() {
    if (!this.generalService.isConnect) { return; }

    let auth = localStorage.getItem('token');
    const headers = { 'Authorization': auth, }

    return this.http.post<any>(environment.server + 'api/deleteUserProfile', {}, { headers })

  }
}
