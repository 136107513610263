import { Component, OnInit } from '@angular/core';
import { GeneralService } from '../../services/general.service'; //== General service
import { SpotsService } from './../../services/spots.service'; //== Spot
import { LoginUser, ModelChangeUserAvatar } from './../../components/models/user-data.model';
import { ModalManipulationAvatarComponent } from './modal-manipulation-avatar/modal-manipulation-avatar.component';
import { ModalEditUserDataComponent } from './modal-edit-user-data/modal-edit-user-data.component';
import { ModSpotManipulationComponent } from './../../components/mod-spot-manipulation/mod-spot-manipulation.component';
import { Router } from '@angular/router';
import { NgbModal, NgbActiveModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

export class ModelDataProfile {

  constructor(
    public email: string,
    public emailValidated: boolean

  ) { }
}

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})

export class UserProfileComponent implements OnInit {

  public miniLoading: boolean = true;
  public isCollapsedPass: boolean = true;
  public inviaNewDatiUserUi: boolean = false;
  public inviaNewPassUserUi: boolean = false;
  public usernameUi = this.spotsService.username;
  public avatarUi = this.spotsService.urlAvatar;

  active;

  public modelDataProfile = new ModelDataProfile(this.spotsService.emailUser, false);
  public modelChangeUserAvatar: ModelChangeUserAvatar;
  public user: LoginUser;

  public emailValidated: boolean;
  public defaultAvatarSet: boolean;

  nummySpot = this.spotsService.mySpots.length;
  numFollowing = this.spotsService.dataUser.followingSpots.length;

  constructor(
    public generalService: GeneralService,
    private modalService: NgbModal,
    private router: Router,
    public spotsService: SpotsService,

  ) {

    this.generalService.positionPage='privateProfile';
    
   }

  ngOnInit() {

    if (!this.spotsService.couldBeLogged()) {

      this.spotsService.destroyprofileUser();
      this.router.navigate(['/login', {}]);


    } else {
      this.modelDataProfile.email = '';
    }

  }

  getDataUserEdition() {

    if (!this.generalService.isConnect) { 
      
      if (localStorage.getItem('utente')) {
      
        let utente = JSON.parse(localStorage.getItem('utente'));
      
        this.modelDataProfile = {
          email: utente['email'],
          emailValidated: utente['mailValidated']
        }
      }
      
      return; 
    }

    this.spotsService.userDetails().subscribe(data => {


      if (data['success']) {

        this.modelDataProfile = {
          email: data['userDetails']['email'],
          emailValidated: data['userDetails']['mailValidated']
        }

        this.miniLoading = false;

      } else {}

    }, error => {


      this.generalService.crashServer(error)
    });
  }


  openEditorImage() {

   // console.log(this.spotsService.urlAvatar);
    let modal = this.modalService.open(ModalManipulationAvatarComponent, {});
    (<ModalManipulationAvatarComponent>modal.componentInstance).imageToEdit = this.spotsService.dataUser.defaultAvatarSet == true ? 'none' : this.spotsService.dataUser.urlAvatar;
    (<ModalManipulationAvatarComponent>modal.componentInstance).maintainAspectRadio = true;
    modal.result.then((result) => {
      this.spotsService.setUserDetails(false);
    }, (reason) => {
      this.spotsService.setUserDetails(false);
    });

  }

  openEditorDataUser() {

    let modal = this.modalService.open(ModalEditUserDataComponent, { size: 'xl' });
    modal.result.then((result) => {
     // this.spotsService.setUserDetails(false);
    }, (reason) => {
     // this.spotsService.setUserDetails(false);

    });

  }

  openFormNuovoSpot() {
    let modal = this.modalService.open(ModSpotManipulationComponent, { size: 'lg', backdrop: 'static' });
    (<ModSpotManipulationComponent>modal.componentInstance).type = 'new';
  }

  onImgError(event) {
    event.target.src = this.generalService.defAvatarUrl;
    //Do other stuff with the event.target
  }
  

  validaMail(){
   // alert('msg')
  }
}
