import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ValidatorPasswordService {

 
  public newPassword = '';
  public repeatPassw = '';
  public validPassword: boolean = false;
  public coincidencePassword: boolean = false;
  public messageError: Array<string> = [];

  public showErrornewPasswordRequired = false;
  public showErrorrepeatPasswRequired = false;
  public showErrorrepeatPasswNoCoincidence = false;
  public showErrorPasswordRequirements = false;

  constructor() { }

  getAndSetValidatedData() {

    this.showErrornewPasswordRequired = this.newPassword.replace(/(^\s*)|(\s*$)/g, "") == '' ? true : false;
    this.showErrorrepeatPasswRequired = this.repeatPassw.replace(/(^\s*)|(\s*$)/g, "") == '' ? true : false;
    this.showErrorrepeatPasswNoCoincidence = this.repeatPassw != this.newPassword ? true : false;

    this.showErrorPasswordRequirements = this.validPassword ? false : true;

    if (this.newPassword != this.repeatPassw) {
      this.messageError.push('Le passwords non corrispondono');
      this.validPassword = false;
    }

    let resultForm = {
      validPassword: this.validPassword,
      errors: this.messageError
    }

    return resultForm;

  }



  // When the user starts to type something inside the password field
  evaluaPass() {

    let validator = true;
    let msg = [];

    // Validate lowercase letters
    var lowerCaseLetters = /[a-z]/g;
    if (this.newPassword.match(lowerCaseLetters)) { } else {
      msg.push('La password deve contenere una lettera maiuscola')
    }

    // Validate capital letters
    var upperCaseLetters = /[A-Z]/g;
    if (this.newPassword.match(upperCaseLetters)) { } else {
      msg.push('La password deve contenere una lettera minuscola')

    }

    // Validate numbers
    var numbers = /[0-9]/g;
    if (this.newPassword.match(numbers)) { } else {
      msg.push('La password deve contenere un numero')
    }

    // Validate length
    if (this.newPassword.length >= 8) { } else {
      msg.push('La password deve avvere minimo 8 caratteri')
    }

    this.validPassword = validator ? true : false;
    this.showErrorPasswordRequirements = true;
    return msg;
  }

  cleanForm() {

    this.newPassword = '';
    this.repeatPassw = '';
    this.validPassword = false;
    this.coincidencePassword = false;
    this.messageError = [];

    this.showErrornewPasswordRequired = false;
    this.showErrorrepeatPasswRequired = false;
    this.showErrorrepeatPasswNoCoincidence = false;
    this.showErrorPasswordRequirements = false;

  }


}
