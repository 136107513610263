import { Component, OnInit } from '@angular/core';
import { GeneralService } from './../../services/general.service';

@Component({
  selector: 'app-overload',
  templateUrl: './overload.component.html',
  styleUrls: ['./overload.component.css']
})
export class OverloadComponent implements OnInit {

  constructor(public generalService: GeneralService) { }

  ngOnInit(): void { }

}
