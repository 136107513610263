export class UserData {

    public _id: string;
    public certificateUser: boolean;
    public username: string;
    public urlAvatar: string;
    public defaultAvatarSet: boolean;
    public userEmail: string;
    public mailValidated: boolean;
    public signDate: string;
    public mySpots: Array<any>;
    public followingSpots: Array<any>;

    constructor(

        iDen: string,
        cert: boolean,
        name: string,
        urlA: string,
        defA: boolean,
        usEm: string,
        EmVa: boolean,
        sign: string,
        mySp: Array<any>,
        foll: Array<any>,

    ) {

        this._id = iDen;
        this.certificateUser = cert;
        this.username = name;
        this.urlAvatar = urlA;
        this.defaultAvatarSet = defA;
        this.userEmail = usEm;
        this.mailValidated = EmVa;
        this.signDate = sign;
        this.mySpots = mySp;
        this.followingSpots = foll;

    }

}




export class ModelChangeUserAvatar {

    constructor(
        public newPassword: string,

    ) { }
}

export class UserDataProfile {

    constructor(
        public username: string,
        public urlAvatar: string,
        public urlAvatarThumb: string,
        public defaultAvatarSet: boolean,
        public userEmail: string,
        public mailValidated: boolean,
    ) { }
}






export class Ricupero {
    constructor(
        public username: string
    ) { }
}

export class ResetPaswword {
    constructor(
        
        public code: string,
        public password: string,
    ) { }
}

export class LoginUser {
    constructor(
        public username: string,
        public password: string,
    ) { }
}


export class NewUser {
    constructor(
        public username: string,
        public password: string,
    ) { }
}


export class UserDataNav {

    constructor(
        public username: string,
        public urlAvatar: string,
    ) { }

}


export class StatusImage {

    constructor(
        public isResult: boolean,
        public success: boolean,
        public message: string,
        public url: string,
        public filename: string
    ) { }

}

  export class ModelDataForChangeUserProfile {
  
    constructor(
      public newEmail: string,
      public emailValidated: boolean
      
    ) { }
  }
  
  export class ModelChangeUserPassword {
  
    constructor(
      public newPassword: string,
    ) { }
  }
  
  export class ModelValidatorPassword {
  
    constructor(
      public oldPassword: string,
      public newPassword: string,
      public repeatPassword: string,
  
    ) { }
  }


