import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ModImageManipulationService {

  public resultImage: any;
  public imgResultBeforeCompress:string;
  public imgResultAfterCompress:string;
  public srcOldPreview: string= '';

  constructor() { }

  

}
