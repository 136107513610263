import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-aviso-inicial',
  templateUrl: './aviso-inicial.component.html',
  styleUrls: ['./aviso-inicial.component.css']
})
export class AvisoInicialComponent implements OnInit {
  viewAviso = true;
  constructor() { }

  ngOnInit(): void {
  }
  fueraAviso(){
    this.viewAviso = false;
  }
}
